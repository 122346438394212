import Select from "react-select"
import AsyncSelect from 'react-select/async'
import { SELECT_STYLES } from "../../constants/styles"
import { useEffect } from "react"

export const ReactSelect = ({ options, field, form, styles }) => {
    return (
        <Select
            options={options}
            name={field.name}
            value={options ? options.find(option => option.value === field.value) : ''}
            onChange={option => form.setFieldValue(field.name, option.value)}
            onBlur={field.onBlur}
            styles={styles}
        />
    )
}

const labelFilter = (option, inputValue) => {
    const labelMatches = option.label.toLowerCase().includes(inputValue.toLowerCase());
    return labelMatches;
}

export const ReactAsyncSelect = ({ options, field, form, styles, asyncOptions }) => {

    return (
        <AsyncSelect
            name={field.name}
            onChange={option => form.setFieldValue(field.name, option.value)}
            onBlur={field.onBlur}
            filterOption={labelFilter}
            cacheOptions={asyncOptions.cache}
            defaultOptions={asyncOptions.defaultOptions ?? true }
            styles={styles}
            loadOptions={asyncOptions.loadOptions}
            defaultValue={asyncOptions.default}
            isClearable={true}
        />
    )
}