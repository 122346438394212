import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/sharp-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import AppContext from "../context/AppContext";

import Navigation from './Navigation';

import Logo from "../assets/images/southern_logo.png";


const Header = () => {

  const user = useSelector(state => state.user.data);
  const context = useContext(AppContext);

return(
    <header className="app-header">
        <div className="header-main">
          <div className="logo-column">
            <div className="logo"><Link to="/"><img src={Logo} alt="Southern Motorcycle Supply Inc." /></Link></div>
            <h1><span style={{ color: "#000" }}>|</span><hr style={{ borderColor: '#000' }} />Dealer Portal</h1>
          </div>
          {window.innerWidth >= 768 && (
          <div className="nav-column">
            <div className="nav">
              <p><a href={`https://${process.env.REACT_APP_DOMAIN}/`}>Return to Online Catalog</a></p>
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </div>
          </div>
          )}
        </div>
        <Navigation />
        {(user.dealer.suspended && context.token !== null)&&  (
          <div className='suspended-bar'>
            <Link to ="/">Account Suspended</Link>
          </div>
        )}
      </header>
)
}


export default Header;