import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import api from "../../api";

const ManageUserPassword = () => {
    let { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            try {
                let request = await api.post("/user", { id: id });
                setUser(request.data.user);
            } catch (err) {
                console.warn(err);
            }

        }
        if (user === null) {
            getUser();
        }
    })

    if (user === null) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        )
    }

    if (user === "done") {
        return (
            <div className="manage-user">
            <h1>Reset Password Confirmation</h1>
            <p>Thank you! The user has been emailed a password reset link.</p>
            <Link to="/admin" className="button button-primary">Return to User Management</Link>
            </div>
        )
    };

    return (
        <div className="manage-user">
            <h1>Reset Password</h1>
            <p>Clicking the button below will send a reset password link { user.role === "dealer" && ( <span>for account #{user.dealer_number} </span>)} to {user.email}.</p>
            <button className="button button-primary" onClick={ async () => {
                try {
                    let result = await api.post('/requestPasswordLink', { email: user.email });
                    if (result.status === 200) {
                        setUser("done");
                    }

                } catch (err) {
                    console.warn(err);
                }
                
            }}>
                Reset Password
            </button>
        </div>
    )
}

export default ManageUserPassword;