import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import CartQuantity from "./CartQuantity";
import { changeQuantity, removeFromCart } from "../redux/cartSlice";
import { Link } from "react-router-dom";
import LinkWrapper from "./LinkWrapper";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

import { useSound } from "use-sound";
import AddToCartSound from "../assets/sounds/add_to_cart.mp3";

const MiniCart = () => {

    const [open, setOpen] = useState(false);

    const { width } = useWindowDimensions();


    const cart = useSelector(state => state.cart.products);
    const dispatch = useDispatch();
    const [ playAdd ] = useSound(AddToCartSound, { interrupt: true }); 

    const previousValuesRef = useRef([]);

    const renderCart = () => {
        if (cart.length === 0) {
            return (
                <div className="cart-empty">There's nothing here. Please add some items to your cart.</div>
            )
        }


        return (
            cart.map((product, index) => {
                let price = product.item.retail_price;
                let dealer_price = product.item.dealer_price;
                let discount_price;
                let discount_quantity;

                if (dealer_price) {
                    if (dealer_price < price) {
                        price = dealer_price;
                    }
                }

                let hasPriceBreak = false;
                if (product.priceBreak !== null) {
                    discount_price = product.priceBreak.discount_price;
                    discount_quantity = product.priceBreak.discount_quantity;
                    if (discount_price < price) {
                        hasPriceBreak = true;
                    };
                }


                return (
                    <div key={product.id} className="result__container">
                        <div className="part-number result">
                            <p className="part-number__data result__data">{product.item.item_number}</p>
                        </div>
                        <div className="part-description result">
                            <p className="part-description__data result__data">{product.item.item_description}</p>
                            {product.item.manufacturer_number !== "" && (
                                <p className="part-manufacturer__data result__data">MFG#: {product.item.manufacturer_number}</p>
                            )}
                        </div>
                        <div className="price result">


                            {!hasPriceBreak && (
                                <p className="price__data result__data">{price.toLocaleString("en-US", { style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            )}

                            {(hasPriceBreak && discount_quantity > product.quantity) && (
                                <>
                                    <p className="price__data result__data">{price.toLocaleString("en-US", { style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    <p className="discount__data result__data">Add <span>{discount_quantity - product.quantity}</span> more to save <span>{(parseFloat(price) - parseFloat(discount_price)).toLocaleString("en-US", { style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> each</p>
                                </>
                            )}
                            {(hasPriceBreak && discount_quantity <= product.quantity) && (
                                <>
                                    <p className="price__data result__data">${discount_price}</p>
                                    <p className="discount__data result__data">Volume discount applied! You're saving <span>{((parseFloat(price) - parseFloat(discount_price)) * product.quantity).toLocaleString("en-US", { style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> </p>
                                </>
                            )}
                        </div>

                        <div className="quantity result">
                            <div className="input">
                                <input
                                    type="number"
                                    className="value"
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value.trim());
                                        console.log(value);

                                        const previousValues = previousValuesRef.current;
                                        const currentValue = previousValues[index] ?? product.quantity;

                                        previousValues[index] = currentValue;
                                        previousValuesRef.current = previousValues;

                                        const regex = /^[1-9]\d*(\.\d+)?$/; // regex to match positive numbers
                                        if (value === "" || value === 0 || isNaN(value)) {
                                            dispatch(changeQuantity({ id: product.id, quantity: 0 }))
                                            return;
                                        }

                                        if (regex.test(value)) {
                                            dispatch(changeQuantity({ id: product.id, quantity: value }));
                                        } else {
                                            console.log("Cart quantity changer invalid value");
                                            console.log(previousValues);
                                            e.target.value = previousValues[index];
                                        }
                                    }}
                                    value={parseInt(product.quantity).toString()}
                                />
                                <span onClick={(e) => {
                                    dispatch(changeQuantity({ id: product.id, quantity: product.quantity + 1 }));
                                }} className="inc"><FontAwesomeIcon icon={solid("chevron-up")} /></span>
                                <span onClick={(e) => {
                                    dispatch(changeQuantity({ id: product.id, quantity: product.quantity - 1 }));
                                }} className="dec"><FontAwesomeIcon icon={solid("chevron-down")} /></span>

                            </div>

                        </div>

                        <div className="subtotal result">
                            {(!hasPriceBreak || (hasPriceBreak && discount_quantity > product.quantity)) && (
                                <p className="subtotal__data result__data">${(price * product.quantity).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            )}
                            {(hasPriceBreak && discount_quantity <= product.quantity) && (
                                <p className="subtotal__data result__data">{(discount_price * product.quantity).toLocaleString("en-US", { style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            )}
                        </div>

                        <div className="item-remove">
                            <FontAwesomeIcon icon={solid("trash-xmark")} color="#A91108" onClick={
                                (e) => { 
                                    e.target.classList.add("pulse-animation");
                                    playAdd();
                                    dispatch(removeFromCart(product.id)) 
                                }
                            } onAnimationEnd={ (e) => {
                                e.target.classList.remove("pulse-animation");
                            }}

                            />
                        </div>
                    </div>
                )
            }
            )
        )
    }

    return (
        <div className={`mini-cart ${open ? "is-open" : ""}`}>
            {!open && (
                <div className="icon-container" onClick={() => {
                    setOpen(true);
                    if (width <= 1024 ) {
                        document.body.style = "overflow: hidden";
                    }
                    
                }}>
                    <FontAwesomeIcon icon={solid("shopping-cart")} size="3x" color="#A91108" />
                    <CartQuantity />
                </div>

            )}

            <div className="cart-container">
                <div className="cart-dismiss" onClick={() => { 
                    setOpen(false); 
                    if (width <= 1024 ) {
                        document.body.style = "";
                    }
                }}>
                    <FontAwesomeIcon icon={solid("xmark")} size="2x" color="#A91108" />
                </div>
                <div className="cart-content">
                    {renderCart()}
                    <LinkWrapper link="/cart" label="View Cart" position="right" containerClass="button button-primary" wrapperStyles={{ justifyContent: "flex-end" }} onClick={() => {
                        setOpen(false);
                        if (width <= 1024 ) {
                            document.body.style = "";
                        }
                    }}>
                        <FontAwesomeIcon icon={solid('chevron-right')} />
                    </LinkWrapper>
                </div>
            </div>


        </div>
    )
}

export default MiniCart;