import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import Logo from "../assets/images/southern_logo_alt.png";

const Footer = () => {
return(
    <footer className="app-footer">
    <div className="footer-main">
      <div className="footer-logo">
        <img src={Logo} alt="Southern Motorcycle Supply Inc." />
      </div>
      <div className="footer-blurb">
        <p>Southern provides a complete line of quality powersports parts and accessories to dealers, and distributors. We back our products with responsive customer service, competitive pricing, and specialized shipping expertise. Business inquiries are welcome.</p>
        <a href={`https://${process.env.REACT_APP_DOMAIN}/`} className="button">Learn More</a>
      </div>
      <div className="footer-contact">
        <p>Southern Motorcycle Supply, Inc.</p>
        <p style={{ marginBottom: "0.5rem" }}>3670 Ruffin Road, San Diego, California 92123-1810</p>
        <div className="has-icon tel"><FontAwesomeIcon icon={solid('phone')} pull="left" family="sharp" /><a href="tel:858-560-5005">858-560-5005</a></div>
        <div className="has-icon fax"><FontAwesomeIcon icon={solid('fax')} pull="left" family="sharp" /><a href="tel:858-560-4626">858-560-4626</a></div>
        <div className="has-icon mail"><FontAwesomeIcon icon={solid('envelope')} pull="left" family="sharp" /><a href="mailto:southern@southernms.com">southern@southernms.com</a></div>
      </div>
    </div>
    <div className="footer-utility">
      <div className="footer-legal"><p>© {new Date().getFullYear()}, Southern Motorcycle Supply, Inc.</p></div>
      <div className="footer-longevity"><p><a href="https://www.longevitygraphics.com">Web Design</a> by <a href="https://www.longevitygraphics.com">Longevity Graphics</a></p></div>
    </div>
  </footer>
)
}

export default Footer;