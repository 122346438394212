import { useEffect, useState } from "react";
import api from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LinkWrapper from "../components/LinkWrapper";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Logo from "../assets/images/southern_logo_alt.png";

const InvoiceHistoryDetail = () => {

    const [invoice, setInvoice] = useState(null);
    const { id } = useParams();
    const user = useSelector(state => state.user.data);
    const { width } = useWindowDimensions();

    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const getData = async () => {
            let invoiceRequest = await api.post("/invoice-history-detail", { id });

            if (invoiceRequest.data.invoice.retail_pricing) {
                console.log("Must get retail price!");
                let itemNumbers = invoiceRequest.data.invoice.details.filter(obj => obj.item_number !== "").map(obj => obj.item_number);
                console.log(itemNumbers);
                let itemDetails = await api.post("/price-by-item-numbers", { items: itemNumbers });

                invoiceRequest.data.invoice.details.forEach(item => {
                    let match = itemDetails.data.items.find(detailItem => detailItem?.item_number === item?.item_number);
                    if (match) {
                        item.retail_price = match.retail_price;
                    } else {
                        item.retail_price = item.item_cost / item.quantity_ordered
                    }
                })

            }
            console.log(invoiceRequest.data.invoice.details);
            setInvoice(invoiceRequest.data.invoice);
        }
        getData();
    }, [])


    if (invoice === null) {
        return (
            <div>Loading...</div>
        )
    }

    if (invoice !== null && parseInt(invoice.customer_number) !== parseInt(user.dealer_number)) {
        navigate("/invoice-history");
    }

    const renderInvoice = () => {

        if (width < 768) {
            return invoice.details.map((part) => {
                if (part.item_number) {
                    return (
                        <div className="part">
                            <div className="part-number result">
                                <p className="title">Part Number</p>
                                <p className="part-number__data result__data">{part.item_number}</p>
                            </div>

                            <div className="part-description result">
                                <p className="title">Part Description</p>
                                <p className="part-description__data result__data">{part.item_description}</p>
                                {part.manufacturer_number && (
                                    <p className="part-manufacturer__data result__data">MFG#: {part.manufacturer_number}</p>
                                )}
                            </div>
                            {invoice.retail_pricing && (
                                <div className="price result">
                                    <p className="title">Suggested Retail Price</p>
                                    <p className="price__data result__data">${part.retail_price}</p>
                                </div>
                            )}
                            <div className={`dealer-price result ${invoice.retail_pricing ? "" : "no-retail"}`}>
                                <p className="title">Dealer Price</p>
                                <p className="dealer-price__data result__data">{(part.item_cost / part.quantity_ordered).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>


                            <div className="quantity result">
                                <p className="title">Qty Ordered</p>
                                <p className="quantity__data result__data">{part.quantity_ordered}</p>
                            </div>

                            <div className="quantity_shipped result">
                                <p className="title">Qty Shipped</p>
                                <p className="quantity__data result__data">{part.quantity_shipped}</p>
                            </div>

                            <div className="quantity_backordered result">
                                <p className="title">Qty Backordered</p>
                                <p className="quantity__data result__data">{part.quantity_backordered}</p>
                            </div>

                            <div className="subtotal result">
                                <p className="title">Subtotal</p>
                                <p className="subtotal__data result__data">{ parseFloat(part.item_cost).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                        </div>
                    )
                }
            })

        }


        return invoice.details.map((part, index) => {
            if (part.item_number) {
                return (
                    <div key={index} className="result__container">
                        <div className="part-number result">
                            <p className="part-number__data result__data">{part.item_number}</p>
                        </div>
                        <div className="part-description result">
                            <p className="part-description__data result__data">{part.item_description}</p>
                        </div>
                        {invoice.retail_pricing && (
                            <div className="price result">
                                <p className="price__data result__data">{part.retail_price?.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                        )}
                        <div className="dealer-price result">
                            <p className="dealer-price__data result__data">{(parseFloat(part.item_cost) / parseFloat(part.quantity_ordered)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                        <div className="quantity result">
                            <p className="quantity__data result__data">{part.quantity_ordered}</p>
                        </div>
                        <div className="quantity_shipped result">
                            <p className="quantity__data result__data">{part.quantity_shipped}</p>
                        </div>
                        <div className="quantity_backordered result">
                            <p className="quantity__data result__data">{part.quantity_backordered}</p>
                        </div>
                        <div className="subtotal result">
                            <p className="subtotal__data result__data">{parseFloat(part.item_cost).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                    </div>
                )
            }

        })
    }

    console.log(invoice);

    return (
        <div className="invoice-history-detail">
            <img src={Logo} className="print-only print-logo" />
            {invoice.invoice_total < 0 && (
                <h1>Credit Memo #{invoice.invoice_number}</h1>
            )}
            {invoice.invoice_total >= 0 && (
                <h1>Invoice #{invoice.invoice_number}</h1>
            )}
            <p>Southern Order #{invoice.order_number}</p>
            <p className="mb-1">PO # {invoice.po_number}</p>
            <p className="bold ">Account #{invoice.customer_number}</p>
            <p className="bold mb-2">{user.dealer.name_company}</p>
            <p>Sales Rep #: {invoice.sales_rep}</p>
            <p>Entry Date: {invoice.order_entry_date}</p>
            <p>Invoice Date: {invoice.order_invoice_date}</p>
            <p className="">Ship Method Noted: {invoice.shipping_instructions}</p>
            {invoice.purchase_terms && (
                <p className="mb-2">Payment Terms: {invoice.purchase_terms}</p>
            )}
            {invoice.misc_note && (
                <p className="mb-2">Special Information: {invoice.misc_note}</p>
            )}
            <div className="address-container mb-2">
                <div className="billing">
                    <p>Billing Address:</p>
                    <p className="bold">{user.dealer.address.billing.name}</p>
                    <p className="mb-0">{user.dealer.address.billing.street}</p>
                    <p className="mt-0">{user.dealer.address.billing.city} {user.dealer.address.billing.state}, {user.dealer.address.billing.postal}</p>
                </div>
                <div className="shipping">
                    <p>Ship-to Address:</p>
                    <p className="bold">{user.dealer.address[invoice.ship_address].name}</p>
                    <p className="mb-0">{user.dealer.address[invoice.ship_address].street}</p>
                    <p className="mt-0"> {user.dealer.address[invoice.ship_address].city} {user.dealer.address[invoice.ship_address].state}, {user.dealer.address[invoice.ship_address].postal}</p>
                </div>
            </div>
            <LinkWrapper onClick={() => { window.print() }} to="#" label="Download/Print" className="download-link">
                <FontAwesomeIcon icon={solid("download")} />
            </LinkWrapper>
            <div className="invoice-container">
                {width >= 768 && (
                    <div className="invoice-header">
                        <p className="part-number">Part Number</p>
                        <p className="part-description">Part Description</p>
                        {invoice.retail_pricing && (
                            <p className="price">Suggested Retail Price</p>
                        )}
                        <p className="dealer-price">Dealer Price</p>
                        <p className="quantity">Qty Ordered</p>
                        <p className="quantity_shipped">Qty Shipped</p>
                        <p className="quantity_backordered">Qty Backordered</p>
                        <p className="subtotal">Subtotal</p>
                    </div>
                )}
                {renderInvoice()}
            </div>
            <div className="box-container">
                <div className="cost-container">
                    <div className="cost">
                        <span className="title">Items Subtotal</span>
                        <span className="value">{invoice.details.reduce((total, item) => { return parseFloat(total) + parseFloat(item.item_cost); }, 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="cost">
                        <span className="title">Shipping</span>
                        <span className="value">{invoice.details.reduce((total, item) => { return parseFloat(total) + parseFloat(item.special_charge_amount); }, 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })} </span></div>
                    <div className="cost">
                        <span className="title">Taxes</span>
                        <span className="value">{invoice.sales_tax.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                </div>
                <div className="total-container">
                    <div className="title">
                        {invoice.invoice_total < 0 && (
                            <span>Credit Memo Total</span>
                        )}
                        {invoice.invoice_total >= 0 && (
                            <span>Invoice Total</span>
                        )}</div>
                    <div className="value">
                        <span className="amount">{(invoice.details.reduce((total, item) => { return parseFloat(total) + parseFloat(item.item_cost); }, 0) + invoice.details.reduce((total, item) => { return parseFloat(total) + parseFloat(item.special_charge_amount); }, 0) + invoice.sales_tax).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        <span className="due">
                            {invoice.invoice_total < 0 && (
                                `Credit Memo Do Not Pay`
                            )}
                            {invoice.invoice_total >= 0 && (
                                `Payment due on or before: ${invoice.date_payment_due}`
                            )}

                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <LinkWrapper link="/invoice-history" label="Back to Invoice History" containerClass="button button-primary" wrapperClass="d-flex" />
            </div>
        </div>
    )
}

export default InvoiceHistoryDetail;