import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useSelector } from "react-redux";

import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";

import api from "../api";
import { useNavigate } from 'react-router-dom';

const SaveCart = () => {

    const saveValidation = Yup.object().shape({
            order_name: Yup.string().required("Name is Required")
    })

    const user = useSelector(state => state.user.data);
    const cart = useSelector(state => state.cart.products);
    const navigate = useNavigate();

    return(
        <div>
            <h1>Save Order</h1>
            <p>Give your saved order a name to save it. Please note that you can only have a maximum of 5 saved orders. If you exceed this amount, you will need to delete one of your saved orders before you can proceed.</p>
            <Formik
                initialValues={{ order_name: "" }}
                validationSchema={saveValidation}
                onSubmit={async (values, { setSubmitting }) => {

                    let result;

                    if ( user.role === 'dealer' ) {
                        result = await api.post("saveOrder", { name: values.order_name, order: cart }).catch( (err) => {
                            console.log(err);
                            let header = "Something Went Wrong";
                            if (err.response.status === 409 ) { header = "Could Not Save Order"}
                            toast.custom(
                                (t) => (
                                    <NotificationToast header={header} body={err.response.data.message} type="danger" toastObject={t} />
                                ), { duration: 4000, id: "save-error"}
                            );
                        });
                        
                    } else {
                        result = await api.post("saveOrder", { name: values.order_name, order: cart, user: user.dealer.customer_number  }).catch( (err) => {
                            console.log(err);
                            let header = "Something Went Wrong";
                            if (err.response.status === 409 ) { header = "Could Not Save Order"}
                            toast.custom(
                                (t) => (
                                    <NotificationToast header={header} body={err.response.data.message} type="danger" toastObject={t} />
                                ), { duration: 4000, id: "save-error"}
                            );
                        })
                    }
                    console.log(result.data);
                    setSubmitting(false);
                    navigate("/order-saved");

                   

                    
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="field-container">
                            <label htmlFor="order_name">Order Name</label>
                            <ErrorMessage name="order_name" className='error' component="div" />
                            <Field type="order_name" name="order_name" id="order_name" />
                           
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SaveCart;