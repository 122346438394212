import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataAndAddtoCart } from "../redux/cartSlice";
import { changeQuantity } from "../redux/cartSlice";
import { useSound } from "use-sound";

import BarLoader from "react-spinners/BarLoader";

import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Formik, Form, Field } from "formik";

import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";

import AddToCartSound from "../assets/sounds/add_to_cart.mp3";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const StockOrder = () => {

    const [search, setSearch] = useState({ status: null, term: "", last: "" });
    const [results, setResults] = useState([]);
    const [addQuants, setAddQuants] = useState([]);
    const [resultIndex, setResultIndex] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);

    const addButtonRef = useRef(null);


    const cart = useSelector(state => state.cart.products);
    const reduxUser = useSelector(state => state.user.data);
    const dispatch = useDispatch();
    const [ playAdd ] = useSound(AddToCartSound, { interrupt: true }); 

    window.setSearch = setSearch;


    const onSearch = async () => {



        setSearch({ ...search, status: "searching", last: search.term, message: undefined });
        if (search.term === "" || search.term === undefined) {
            setSearch({ ...search, status: "error", message: "Search must contain at least one character." })
            return;
        }
        const illegalCharacters = /(?:\\(?=$)|(?![\s\S]*^)\\|[*?<>|\[\]])/;
        if (illegalCharacters.test(search.term)) {
            setSearch({ ...search, status: "error", message: `Search must not contain any of the following characters: \n\n \\ * ? < > [ ] | ` })
            return;
        }

        try {
            const response = await api.post("items", { search: search.term.toLowerCase(), customer_number: reduxUser.dealer_number });
            console.log(response);
            setResults(response.data)

            console.log(response.data);
            setResults(response.data.items);
            setSearch({ ...search, status: "done", last: search.term });
            setAddQuants(Array(response.data.items.length).fill(1));
            setResultIndex(0);

        } catch (err) {
            console.log(err);
            setSearch({ ...search, status: "error", last: search.term, message: "An Unexpected Error Occurred, please try again later." });
        };

    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearch();
        }
    }

    const renderSearchResults = (values, setFieldValue) => {
        if (results.length > 0) {

            let final = []

            for (let index = resultIndex; index < resultIndex + resultsPerPage; index++) {
                if (results[index] !== undefined) {

                    let dealerPrice = isNaN(results[index].dealer_price) ? results[index].retail_price : results[index].dealer_price;

                    final.push(

                        <div key={index} className="result__container">
                            <div className="part-number result">
                                <p className="part-number__label result__label">Part Number:</p>
                                <p className="part-number__data result__data">{results[index].item_number}</p>
                            </div>
                            <div className="part-description result">
                                <p className="part-description__label result__label">Part Description:</p>
                                <p className="part-description__data result__data">{results[index].item_description}</p>
                                {results[index].manufacturer_number !== "" && (
                                    <p className="part-manufacturer__data result__data">MFG#: {results[index].manufacturer_number}</p>
                                )}
                            </div>
                            <div className="quantity result">
                                <p className="quantity__label result__label">Quantity:</p>
                                <p className="quantity__data result__data">{results[index].quantity <= 0 ? <span>OUT OF STOCK</span> : results[index].quantity} </p>
                            </div>
                            <div className="price result">
                                <p className="price__label result__label">Suggested Retail Price:</p>
                                <p className="price__data result__data">${results[index].retail_price.toFixed(2)}</p>
                            </div>
                            <div className="dealer_price result">
                                <p className="dealer_price__label result__label">Dealer Price:</p>
                                <p className="dealer_price__data result__data">${dealerPrice.toFixed(2)}</p>
                            </div>
                            <div className="quantity-selector result">
                                <div className="input">
                                    <input
                                    type="number"
                                    className="value"
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value.trim());
                                        console.log(value);
                                        let quants = [...addQuants];
                                        const regex = /^[1-9]\d*(\.\d+)?$/; // regex to match positive numbers
                                        if (value === "" || value === 0  || isNaN(value)) {
                                            quants[index] = 0;
                                            setAddQuants(quants);
                                            return;
                                        }

                                        if (regex.test(value)) {
                                            quants[index] = value
                                            setAddQuants(quants);
                                        } else {
                                            console.log("orderform quantity changer invalid value");
                                        }


                                    }}
                                    value={parseInt(addQuants[index]).toString()}
                                    
                                />
                                    <span onClick={(e) => {
                                        let quants = [...addQuants];
                                        quants[index]++;
                                        console.log(quants[index]);
                                        setAddQuants(quants);
                                    }} className="inc"><FontAwesomeIcon icon={solid("chevron-up")} /></span>
                                    <span onClick={(e) => {
                                        let quants = [...addQuants];
                                        if (addQuants[index] <= 0)  {
                                            return;
                                        }
                                        quants[index]--;
                                        setAddQuants(quants);
                                    }} className="dec"><FontAwesomeIcon icon={solid("chevron-down")} /></span>
                                </div>
                            </div>
                            <div className="add-to-cart result">
                                <FontAwesomeIcon className="" icon={solid("cart-plus")} color="#A91108" onClick={(e) => {
                                    let addQuant = addQuants[index] ? addQuants[index] : 1;
                                    console.log(e.target);
                                    e.target.classList.add("pulse-animation");

                                    playAdd();
                                    dispatch(fetchDataAndAddtoCart({ id: results[index].item_number, customer_number: reduxUser.dealer_number, quantity: addQuant, note: "" }))
                                        .then((action) => {
                                            if (action.type === 'cart/fetchData/rejected') {
                                                throw new Error(JSON.stringify(action.payload));
                                            }
                                        }).catch((err) => {

                                            let error = JSON.parse(err.message);
                                            switch (error.code) {
                                                case 400: {
                                                    toast.custom(
                                                        (t) => (
                                                            <NotificationToast header={'Add to Cart Failed'} body={"Please Select a Dealer before adding items to cart."} type="danger" toastObject={t} />
                                                        ), { duration: 4000, id: "login-failed" }
                                                    );
                                                    return
                                                }
                                                default: {
                                                    toast.custom(
                                                        (t) => (
                                                            <NotificationToast header={'Add to Cart Failed'} body={"An Unknown Error Occurred Please try again later."} type="danger" toastObject={t} />
                                                        ), { duration: 4000, id: "login-failed" }
                                                    );
                                                    return
                                                }
                                            }

                                        });

                                    
                                    

                                }} onAnimationEnd={ (e) => {
                                    e.target.classList.remove("pulse-animation");
                                }} />
                            </div>
                        </div>

                    );
                };
            }


            final.push(<div key="pagination" className="pagination">
                <p className={`prev ${resultIndex > 0 ? '' : "disabled"}`} onClick={() => {

                    if (resultIndex >= resultsPerPage) {
                        setResultIndex(resultIndex - resultsPerPage);
                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                            let top = document.getElementById("searchTop").offsetTop;
                            console.log(top);
                            window.scrollTo({ top: top, behavior: 'smooth' });
                        }
                    }


                }}> Previous Page</p>
                <p className={`next ${resultIndex + resultsPerPage < results.length ? '' : "disabled"}`} onClick={() => {

                    if (resultIndex + resultsPerPage < results.length) {
                        setResultIndex(resultIndex + resultsPerPage);
                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                            let top = document.getElementById("searchTop").offsetTop;
                            console.log(top);
                            window.scrollTo({ top: top, behavior: 'smooth' });
                        }
                    }
                }}> Next Page</p>
            </div>)

            return final;
        }
        else return <p>Please try another search</p>
    }

    return (
        <div className="stock-order">
            <h1>Advanced Stock Check & New Orders</h1>
            <p>Enter a part number or keywords to search.</p>
            <div className="search-bar">
                <input type="text" onChange={(e) => { setSearch({ ...search, term: e.target.value }) }} onKeyDown={onKeyDown} />
                <button onClick={onSearch}>Search</button>
            </div>
            {search.status === "searching" && (
                <div className="loading">
                    <p>Search in progress... </p>
                    <div>
                        <BarLoader color="#A91108" height={"2px"} />
                    </div>
                </div>

            )}
            {(search.last && search.status === "done") && (
                <>
                    <hr />


                    <div className="search-results">

                        <div className="search-results__stats" id="searchTop">
                            <p> {results.length} Search Results for "{search.last}"</p>
                            <p>Results {resultIndex + 1}-{resultIndex + resultsPerPage > results.length ? results.length : resultsPerPage + resultIndex} of {results.length} </p>
                            <div className="pagination-selector">
                                <span>Results per page</span>
                                <select onChange={(e) => setResultsPerPage(parseInt(e.target.value))} selected={resultsPerPage}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                </select>
                            </div>
                        </div>
                        {resultsPerPage > 10 &&
                            <div key="pagination" className="pagination">
                                <p className={`prev ${resultIndex > 0 ? '' : "disabled"}`} onClick={() => {

                                    if (resultIndex >= resultsPerPage) {
                                        setResultIndex(resultIndex - resultsPerPage);
                                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                                            let top = document.getElementById("searchTop").offsetTop;
                                            console.log(top);
                                            window.scrollTo({ top: top, behavior: 'smooth' });
                                        }
                                    }


                                }}> Previous Page</p>
                                <p className={`next ${resultIndex + resultsPerPage < results.length ? '' : "disabled"}`} onClick={() => {

                                    if (resultIndex + resultsPerPage < results.length) {
                                        setResultIndex(resultIndex + resultsPerPage);
                                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                                            let top = document.getElementById("searchTop").offsetTop;
                                            console.log(top);
                                            window.scrollTo({ top: top, behavior: 'smooth' });
                                        }
                                    }
                                }}> Next Page</p>
                            </div>
                        }

                        <Formik
                            initialValues={{ results: [] }}
                            onSubmit={values => {
                                // same shape as initial values
                                console.log(values);
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    {renderSearchResults(values, setFieldValue)}
                                </Form>
                            )}

                        </Formik>
                    </div>
                </>
            )}
            {search.status === "error" && (
                <div className="error">
                    <p> {search.message}</p>
                </div>
            )}
        </div>
    )
}

export default StockOrder;