
import axios from "axios";

let baseURL = process.env.NODE_ENV === 'development' ? `https://localhost:8081/` : '/';

const api = axios.create({
    baseURL: baseURL,
    timeout: 15000,
    withCredentials: true,
});

export default api;