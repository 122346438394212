import { useEffect, useState } from "react";
import api from "../api";
import { useSelector } from "react-redux";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";



const OpenOrders = () => {

    const user = useSelector(state => state.user.data);

    const [orders, setOrders] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const getData = async () => {
            try {
                let openResult = await api.post('/open-orders', { dealer: user.dealer_number });
                let unconfirmedResult = await api.post('/unconfirmed-orders', { dealer: user.dealer_number });

                unconfirmedResult.data.orders.forEach( order => {
                    order.status = "unconfirmed";
                } )
                openResult.data.orders.forEach( order => {
                    order.status = "confirmed";

                })

                let orders = [...openResult.data.orders, ...unconfirmedResult.data.orders];
                orders.sort((a, b) => {
                    const dateA = new Date(a.order_date);
                    const dateB = new Date(b.order_date);
                    return dateB - dateA;
                  });
                setOrders(orders);
            }
            catch (err) {
                console.warn(err);
                setOrders([]);
            }
           
        }

        getData();
    }, [])

    if (orders === null) {
        return <div>Loading...</div>
    }
    console.log(orders);


    const renderOrders = () => {

        if (user.role !== 'dealer') {
            if (user.dealer_number === null ) {
                return <p>No dealer selected. Please select a dealer to continue...</p>
            }
        }

        if (orders.length === 0 ) {
            return <p>No Open Orders Found.</p>
        }

        return orders.map((order) => {
            return (
                <div className="order" key={order._id}>
                    <div className="result order__date">
                        <p className="title">Order Date:</p>
                        <p className="result__data result__date">{order.order_date}</p>
                    </div>
                    <div className="result order__po">
                        <p className="title">Purchase Order Number:</p>
                        <Link className="result__data result__po" to={`/open-order/${order.status}/${order._id}`} >{order.po_number === "" ? "Missing P.O." : order.po_number} </Link>
                    </div>
                    <div className="result order__status">
                        <p className="title">Status:</p>
                        <p className="result__data result__status" >{order.pending_shipment ? "Pending Shipping" : order.status } </p>
                    </div>

                    { user.role === "superUser" && (
                        <div className="result delete">
                            { order.status === "unconfirmed" && (
                                <FontAwesomeIcon icon={solid("trash-xmark")} color="#A91108" className="trash" onClick={ () => {
                                    navigate(`/unconfirmed-orders/${order._id}/delete`);
                                }}/>
                            )}
                        </div>
                    )}
                    
                </div>
            )
        })
    }

    return (
        <div>
            <h1>Open Orders</h1>
            <p>Orders that have been placed but not yet shipped. Orders are confirmed by the Southern Motorcycle customer service team regularly throughout normal business hours.</p>
            <div className="orders-container">
                {renderOrders()}
            </div>
        </div>
    )
}

export default OpenOrders;