import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import api from "../api";

import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {

    const token = window.location.pathname.split("/")[2];
    const [status, setStatus] = useState("loading");

    const navigate = useNavigate();

    //console.log(atob(token));

    useEffect(() => {
        const validateToken = async () => {
            try {
                let result = await api.post('/validateToken', { token }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setStatus(result.data.status);
            }
            catch (err) {
                console.log(err);
            }
        }
        validateToken();
    }, [])

    const resetPasswordValidation = Yup.object().shape({
        password: Yup.string().min(8, 'Password must be at least 8 characters long')
            .max(64, 'Password must be at most 64 characters long')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            )
            .required('Password is required'),
        password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required.')
    })

    if (status === "loading") {
        return (
            <div><p>Loading...</p></div>
        )
    }

    if (status === "expired") {
        return (
            <div>
                <p>This link has expired or already been used. Please request a new link.</p>
            </div>
        )
    }


    return (
        <div className='forgot-password'>
            <h1>Reset Password</h1>
            <Formik
                initialValues={{ password: "", password_confirm: "" }}
                validationSchema={resetPasswordValidation}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        let result = await api.post("changePassword", { password: values.password }, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        console.log(result);
                        setSubmitting(false);
                        navigate("/login");
                    } catch (err) {
                        console.warn(err);

                        toast.custom(
                            (t) => (
                                <NotificationToast header={'Password Reset Failed'} body={err.response.data.message} type="danger" toastObject={t} />
                            ), { duration: 4000, id: "reset-failed" }
                        );

                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="field-container">
                            <label htmlFor="password">New Password</label>
                            <ErrorMessage name="password" component="div" className='error' />
                            <Field type="password" name="password" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="password_confirm">Retype New Password</label>
                            <ErrorMessage name="password_confirm" component="div" className='error' />
                            <Field type="password" name="password_confirm" />
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ResetPassword;