import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import api from "../../api";

const ManageUserDisable = () => {
    let { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            try {
                let request = await api.post("/user", { id: id });
                setUser(request.data.user);
            } catch (err) {
                console.log(err);
            }

        }
        if (user === null) {
            getUser();
        }
    })

    if (user === null) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        )
    }

    if (user.state === "done") {
        return (
            <div className="manage-user">
            <h1>{user.status === "active" ? "Enable" : "Disable"} User Confirmation</h1>
            <p>User { user.role === "dealer" ? user.dealer.name_company : <>{user.name_first} {user.name_last} </>} has been {user.status === "active" ? "enabled" : "disabled"}.</p>
            <Link to="/admin" className="button button-primary">Return to User Management</Link>
            </div>
        )
    };

    return (
        <div className="manage-user">
            <h1>{user.status === "active" ? "Disable" : "Enable"} User</h1>
            <p>Are you sure you want to {user.status === "active" ? "disable" : "enable"} user { user.role === "dealer" ? user.dealer.name_company : <>{user.name_first} {user.name_last} </>}? This action will {user.status === "active" ? "prevent them from accessing the website." : "renable them to access the website"}</p>
            <button className="button button-primary" onClick={ async () => {
                try {
                    let result = await api.post("updateUser", { id: user._id, changes: { status: user.status === 'active' ? "disabled" : "active"} });
                    if (result.status === 200) {
                        setUser({...result.data.user, state: "done"});
                    }

                } catch (err) {
                    console.log(err);
                }
                
            }}>
                {user.status === "active" ? "Disable" : "Enable"} User
            </button>
        </div>
    )
}

export default ManageUserDisable;