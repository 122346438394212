import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { changeNote, changeQuantity, removeFromCart } from "../redux/cartSlice";

import LinkWrapper from "../components/LinkWrapper";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import NotificationToast from "../components/NotificationToast";
import { useContext, useEffect, useRef } from "react";
import AppContext from "../context/AppContext";
import ContentEditable from 'react-contenteditable';


import { useSound } from "use-sound";
import AddToCartSound from "../assets/sounds/add_to_cart.mp3";

const Cart = () => {

    const cart = useSelector(state => state.cart.products);
    const user = useSelector(state => state.user.data);
    const dispatch = useDispatch();

    const previousValuesRef = useRef([]);

    const context = useContext(AppContext);
    const [ playAdd ] = useSound(AddToCartSound, { interrupt: true }); 

    let total = 0;

    useEffect(() => {
        context.setShowMiniCart(false);
    })

    const renderCart = () => {
        if (cart.length === 0) {
            return (
                <div className="cart-empty">There's nothing here. Please add some items to your cart.</div>
            )
        }

        return (
            cart.map((product, index) => {
                let price = product.item.retail_price;
                let dealer_price = product.item.dealer_price;
                let discount_price;
                let discount_quantity;

                if (dealer_price) {
                    if (dealer_price < price) {
                        price = dealer_price;
                    }
                }

                let hasPriceBreak = false;
                if (product.priceBreak !== null) {
                    discount_price = product.priceBreak.discount_price;
                    discount_quantity = product.priceBreak.discount_quantity;
                    if (discount_price < price) {
                        hasPriceBreak = true;
                    };
                }

                if (!hasPriceBreak) {
                    total += price * product.quantity;
                } else {
                    if (hasPriceBreak && product.quantity < discount_quantity) {
                        total += price * product.quantity;
                    }
                    if (hasPriceBreak && product.quantity >= discount_quantity) {
                        total += discount_price * product.quantity;
                    }
                }

                return (
                    <div key={product.id} className="result__container">
                        <div className="part-number result">
                            <p className="part-number__data result__data">{product.item.item_number}</p>
                        </div>
                        <div className="part-description result">
                            <p className="part-description__data result__data">{product.item.item_description}</p>
                            {product.item.manufacturer_number !== "" && (
                                <p className="part-manufacturer__data result__data">MFG#: {product.item.manufacturer_number}</p>
                            )}

                        </div>
                        <div className="price result">


                            {!hasPriceBreak && (
                                <p className="price__data result__data">{price.toLocaleString("en-US", { style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            )}

                            {(hasPriceBreak && discount_quantity > product.quantity) && (
                                <>
                                    <p className="price__data result__data">{price.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    <p className="discount__data result__data">Add <span>{discount_quantity - product.quantity}</span> more to save <span>${(parseFloat(price) - parseFloat(discount_price)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> each</p>
                                </>
                            )}
                            {(hasPriceBreak && discount_quantity <= product.quantity) && (
                                <>
                                    <p className="price__data result__data">${discount_price}</p>
                                    <p className="discount__data result__data">Volume discount applied! You're saving <span>{((parseFloat(price) - parseFloat(discount_price)) * product.quantity).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> </p>
                                </>
                            )}
                        </div>

                        <div className="quantity result">
                            <div className="input">
                                <input
                                    type="number"
                                    className="value"
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value.trim());
                                        console.log(value);

                                        const previousValues = previousValuesRef.current;
                                        const currentValue = previousValues[index] ?? product.quantity;

                                        previousValues[index] = currentValue;
                                        previousValuesRef.current = previousValues;

                                        const regex = /^[1-9]\d*(\.\d+)?$/; // regex to match positive numbers
                                        if (value === "" || value === 0  || isNaN(value)) {
                                            dispatch(changeQuantity({id: product.id, quantity: 0}))
                                            return;
                                        }

                                        if (regex.test(value)) {
                                            dispatch(changeQuantity({ id: product.id, quantity: value }));
                                        } else {
                                            console.log("Cart quantity changer invalid value");
                                            console.log(previousValues);
                                            e.target.value = previousValues[index];
                                        }


                                    }}
                                    value={parseInt(product.quantity).toString()}
                                />

                                <span onClick={(e) => {
                                    dispatch(changeQuantity({ id: product.id, quantity: product.quantity + 1 }));
                                }} className="inc"><FontAwesomeIcon icon={solid("chevron-up")} /></span>
                                <span onClick={(e) => {
                                    dispatch(changeQuantity({ id: product.id, quantity: product.quantity - 1 }));
                                }} className="dec"><FontAwesomeIcon icon={solid("chevron-down")} /></span>

                            </div>

                        </div>

                        <div className="subtotal result">
                            {(!hasPriceBreak || (hasPriceBreak && discount_quantity > product.quantity)) && (
                                <p className="subtotal__data result__data">{(price * product.quantity).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            )}
                            {(hasPriceBreak && discount_quantity <= product.quantity) && (
                                <p className="subtotal__data result__data">{(discount_price * product.quantity).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            )}
                        </div>

                        <div className="note result">
                            <p> Note (Optional)</p>
                            <input type="text" value={product.note} onChange={(e) => {
                                dispatch(changeNote({ id: product.id, note: e.target.value }));
                                if (e.target.value.length >= 90) {
                                    toast.custom(
                                        (t) => (
                                            <NotificationToast header={'Character Limit'} body={"Notes must be 91 characters or less."} type="danger" toastObject={t} />
                                        ), { duration: 4000, id: "character-limit" }
                                    );
                                }
                            }} />

                        </div>

                        <div className="item-remove">
                        <FontAwesomeIcon icon={solid("trash-xmark")} color="#A91108" onClick={
                                (e) => { 
                                    e.target.classList.add("pulse-animation");
                                    playAdd();
                                    dispatch(removeFromCart(product.id)) 
                                }
                            } onAnimationEnd={ (e) => {
                                e.target.classList.remove("pulse-animation");
                            }}

                            />
                        </div>
                    </div>
                )
            }
            )

        )
    }

    return (
        <>
            <h1>Cart</h1>
            <LinkWrapper link="/stock" label="Add More Parts to Order" containerClass="button button-primary" />

            <div className="cart-container">
                <div className="cart-content">
                    {renderCart()}
                </div>
                <div className="cart-totals">
                    <p className="small">Total Dealer Price</p>
                    <div className="price-info">
                        <p className="price">${total.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        {user.dealer.freight_minimum !== 0 && (
                            <>
                                {total < user.dealer.freight_minimum && (
                                    <p className="shipping-info" >You need <span>{(user.dealer.freight_minimum - total).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> more for free shipping </p>
                                )}
                                {total >= user.dealer.freight_minimum && (
                                    <p className="shipping-info">Free Shipping</p>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="cart-buttons">
                    <Link to="/checkout" className="button button-primary">
                        <span>Checkout</span>
                        <FontAwesomeIcon icon={solid('chevron-right')} />
                    </Link>
                    <Link to="/save-cart" className="button button-outline" >Save Order for Later</Link>
                </div>
            </div>
        </>
    )
}

export default Cart;