import { useContext, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


import AppContext from "../context/AppContext";

import api from "../api";

import { LABEL_FILTER } from "../constants/filters";
import { SELECT_STYLES_ALT } from "../constants/styles";
import LinkWrapper from "../components/LinkWrapper";


const ManageUser = () => {
    let context = useContext(AppContext);
    const { type } = useParams();


    const [activeUser, setUser] = useState(null)

    const getUserSelectorOptions = async () => {
        try {
            let users = await api.post('/users', { type: type });

            users = users.data.users;
            console.log(users);

            let options = users.map(user => {
                switch (type) {
                    case "dealers": {
                        return ({
                            value: user._id,
                            label: `${user.dealer_number} (${user.dealer.name_company}) ${user.status === "disabled" ? "[ DISABLED ]" : ""}`
                        })
                    }
                    case "sales": {
                        return ({
                            value: user._id,
                            label: `${user.rep_number} (${user.name_first} ${user.name_last}) ${user.status === "disabled" ? "[ DISABLED ]" : ""}`
                        })
                    }
                    case "administrators": {
                        return ({
                            value: user._id,
                            label: `${user.name_first} ${user.name_last} ${user.status === "disabled" ? "[ DISABLED ]" : ""}`
                        })
                    }
                    default: {
                        return;
                    }
                }
            })

            return Promise.resolve(options);
        } catch (err) {
            console.log(err);
        }
    }


    const renderUser = () => {
        if (activeUser === null) {
            return;
        }

        let address;
        let highKey = undefined

        if (type === "dealers") {
            address = activeUser.dealer.address;
            let keys = Object.keys(address).sort((a, b) => parseInt(b) - parseInt(a));
            let i = keys.indexOf('billing');

            if (i !== -1) {
                if (keys.length === 1) {
                    address['9999'] = address['billing'];
                    keys[0] = '9999';
                } else {
                    keys = keys.filter(item => item !== 'billing');
                }
                highKey = keys.shift();
            }
        }

        switch (type) {
            case "dealers": {
                return (
                    <div className="user-data">
                        <p className="bold">Account #{activeUser.dealer_number}</p>
                        <p className="bold">{activeUser.dealer.name_company}</p>
                        <p className="bold">{address[highKey].street}, {address[highKey].city} {address[highKey].state} {address[highKey].postal}</p>
                        <p>{activeUser.email}</p>
                        {(activeUser.accountCreated && !activeUser.lastLogin) && (
                            <p>Account Created - {new Date(activeUser.accountCreated).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" })}</p>
                        )}
                        {activeUser.lastLogin && (
                            <p>Last Login - {new Date(activeUser.lastLogin).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" })}</p>
                        )}

                        <LinkWrapper link={`/manageUser/email/${activeUser._id}/`} label="Edit Email Address">
                            <FontAwesomeIcon icon={solid("pen-to-square")} />
                        </LinkWrapper>
                        <LinkWrapper link={`/manageUser/password/${activeUser._id}/`} label="Reset Password">
                            <FontAwesomeIcon icon={solid("unlock")} />
                        </LinkWrapper>
                        {activeUser.status === "active" && (
                            <LinkWrapper link={`/manageUser/disable/${activeUser._id}/`} label="Disable User">
                                <FontAwesomeIcon icon={solid("user-xmark")} />
                            </LinkWrapper>
                        )}
                        {activeUser.status === "disabled" && (
                            <LinkWrapper link={`/manageUser/disable/${activeUser._id}/`} label="Enable User">
                                <FontAwesomeIcon icon={solid("user-check")} />
                            </LinkWrapper>

                        )}
                        <LinkWrapper link={`/manageUser/delete/${activeUser._id}/`} label="Delete User">
                            <FontAwesomeIcon icon={solid("trash-xmark")} />
                        </LinkWrapper>
                    </div>
                )
            }
            case "sales": {
                return (
                    <div className="user-data">
                        <p>Sales Rep #{activeUser.rep_number}</p>
                        <p>{activeUser.name_first} {activeUser.name_last}</p>
                        <p>{activeUser.email}</p>
                        {(activeUser.accountCreated && !activeUser.lastLogin) && (
                            <p>Account Created - {new Date(activeUser.accountCreated).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" })}</p>
                        )}
                        {activeUser.lastLogin && (
                            <p>Last Login - {new Date(activeUser.lastLogin).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" })}</p>
                        )}
                        <LinkWrapper link={`/dealer-list/${activeUser._id}/`} label="View Dealer List">
                            <FontAwesomeIcon icon={solid("users")} />
                        </LinkWrapper>
                        {context.claims.role === "superUser" && (
                            <>
                                <LinkWrapper link={`/manageUser/edit/${activeUser._id}/`} label="Edit User">
                                    <FontAwesomeIcon icon={solid("pen-to-square")} />
                                </LinkWrapper>
                                <LinkWrapper link={`/manageUser/password/${activeUser._id}/`} label="Reset Password">
                                    <FontAwesomeIcon icon={solid("unlock")} />
                                </LinkWrapper>
                                {activeUser.status === "active" && (
                                    <LinkWrapper link={`/manageUser/disable/${activeUser._id}/`} label="Disable User">
                                        <FontAwesomeIcon icon={solid("user-xmark")} />
                                    </LinkWrapper>
                                )}
                                {activeUser.status === "disabled" && (
                                    <LinkWrapper link={`/manageUser/disable/${activeUser._id}/`} label="Enable User">
                                        <FontAwesomeIcon icon={solid("user-check")} />
                                    </LinkWrapper>

                                )}
                                <LinkWrapper link={`/manageUser/delete/${activeUser._id}/`} label="Delete User">
                                    <FontAwesomeIcon icon={solid("trash-xmark")} />
                                </LinkWrapper>
                            </>
                        )}
                    </div>
                )
            }
            case "administrators": {
                return (
                    <div className="user-data">
                        <p>{activeUser.name_first} {activeUser.name_last}</p>
                        <p>{activeUser.role}</p>
                        <p>{activeUser.email}</p>
                        {(activeUser.accountCreated && !activeUser.lastLogin) && (
                            <p>Account Created - {new Date(activeUser.accountCreated).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" })}</p>
                        )}
                        {activeUser.lastLogin && (
                            <p>Last Login - {new Date(activeUser.lastLogin).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" })}</p>
                        )}
                        <LinkWrapper link={`/manageUser/edit/${activeUser._id}/`} label="Edit User">
                            <FontAwesomeIcon icon={solid("pen-to-square")} />
                        </LinkWrapper>
                        <LinkWrapper link={`/manageUser/password/${activeUser._id}/`} label="Reset Password">
                            <FontAwesomeIcon icon={solid("unlock")} />
                        </LinkWrapper>
                        {activeUser.status === "active" && (
                            <LinkWrapper link={`/manageUser/disable/${activeUser._id}/`} label="Disable User">
                                <FontAwesomeIcon icon={solid("user-xmark")} />
                            </LinkWrapper>
                        )}
                        {activeUser.status === "disabled" && (
                            <LinkWrapper link={`/manageUser/disable/${activeUser._id}/`} label="Enable User">
                                <FontAwesomeIcon icon={solid("user-check")} />
                            </LinkWrapper>

                        )}
                        <LinkWrapper link={`/manageUser/delete/${activeUser._id}/`} label="Delete User">
                            <FontAwesomeIcon icon={solid("trash-xmark")} />
                        </LinkWrapper>
                    </div>
                )
            }
        }


    }


    return (
        <div className="manage-user">
            <h1 style={{ textTransform: "capitalize" }}>Manage {type === "sales" ? "Sales Representatives" : type}</h1>
            <p>Use the search box below to find a user.</p>
            <AsyncSelect
                className="user-selector"
                loadOptions={getUserSelectorOptions}
                filterOption={LABEL_FILTER}
                cacheOptions
                defaultOptions
                styles={SELECT_STYLES_ALT}
                onChange={async (e) => {
                    console.log(e);
                    let details = await api.post("/user", { id: e.value });
                    console.log(details.data.user);
                    setUser(details.data.user);
                }}
            />
            {activeUser !== null && (
                renderUser()
            )}
        </div>
    )
}

export default ManageUser;