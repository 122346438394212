
import { useState } from "react";

import BarLoader from "react-spinners/BarLoader";

import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import api from "../api";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const StockCheck = () => {

    const [search, setSearch] = useState({ status: null, term: "", last: "" });
    const [results, setResults] = useState([]);
    const [resultIndex, setResultIndex] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);


    const onSearch = async () => {



        setSearch({ ...search, status: "searching", last: search.term, message: undefined });
        if (search.term === "" || search.term === undefined) {
            setSearch({ ...search, status: "error", message: "Search must contain at least one character." })
            return;
        }
        const illegalCharacters = /(?:\\(?=$)|(?![\s\S]*^)\\|[*?<>|\[\]])/;
        if (illegalCharacters.test(search.term)) {
            setSearch({ ...search, status: "error", message: `Search must not contain any of the following characters: \n\n \\ * ? < > [ ] | ` })
            return;
        }

        try {
            const response = await api.post("items", { search: search.term.toLowerCase() });
            console.log(response);
            setResults(response.data)

            console.log(response.data);
            setResults(response.data.items);
            setSearch({ ...search, status: "done", last: search.term });

            setResultIndex(0);

        } catch (err) {
            console.log(err);
            setSearch({ ...search, status: "error", last: search.term, message: "An Unexpected Error Occurred, please try again later." });
        };

    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearch();
        }
    }

    const renderSearchResults = () => {
        if (results.length > 0 && results.length <= resultsPerPage) {
            return results.map((result, index) => {
                return (<div key={index} className="result__container">
                    <div className="part-number result">
                        <p className="part-number__label result__label">Part Number:</p>
                        <p className="part-number__data result__data">{result.item_number}</p>
                    </div>
                    <div className="part-description result">
                        <p className="part-description__label result__label">Part Description:</p>
                        <p className="part-description__data result__data">{result.item_description}</p>
                    </div>
                    <div className="quantity result">
                        <p className="quantity__label result__label">Quantity:</p>
                        <p className="quantity__data result__data">{result.quantity <= 0 ? <span>OUT OF STOCK</span> : result.quantity}</p>
                    </div>
                    <div className="price result">
                        <p className="price__label result__label">Suggested Retail Price:</p>
                        <p className="price__data result__data">${result.retail_price.toFixed(2)}</p>
                    </div>
                </div>);
            });
        }
        if (results.length > resultsPerPage) {

            let final = []

            for (let index = resultIndex; index < resultIndex + resultsPerPage; index++) {
                if (results[index] !== undefined) {

                    let price = parseFloat(results[index].retail_price.toFixed(2));
                    final.push(

                        <div key={index} className="result__container">
                            <div className="part-number result">
                                <p className="part-number__label result__label">Part Number:</p>
                                <p className="part-number__data result__data">{results[index].item_number}</p>
                            </div>
                            <div className="part-description result">
                                <p className="part-description__label result__label">Part Description:</p>
                                <p className="part-description__data result__data">{results[index].item_description}</p>
                            </div>
                            <div className="quantity result">
                                <p className="quantity__label result__label">Quantity:</p>
                                <p className="quantity__data result__data">{results[index].quantity <= 0 ? <span>OUT OF STOCK</span> : results[index].quantity} </p>
                            </div>
                            <div className="price result">
                                <p className="price__label result__label">Suggested Retail Price:</p>
                                <p className="price__data result__data">${parseFloat(price).toFixed(2)}</p>
                            </div>
                        </div>

                    );
                };
            }


            final.push(<div key="pagination" className="pagination">
                <p className={`prev ${resultIndex > 0 ? '' : "disabled"}`} onClick={() => {

                    if (resultIndex >= resultsPerPage) {
                        setResultIndex(resultIndex - resultsPerPage);
                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                            let top = document.getElementById("searchTop").offsetTop;
                            console.log(top);
                            window.scrollTo({ top: top, behavior: 'smooth' });
                        }
                    }


                }}> Previous Page</p>
                <p className={`next ${resultIndex + resultsPerPage < results.length ? '' : "disabled"}`} onClick={() => {

                    if (resultIndex + resultsPerPage < results.length) {
                        setResultIndex(resultIndex + resultsPerPage);
                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                            let top = document.getElementById("searchTop").offsetTop;
                            console.log(top);
                            window.scrollTo({ top: top, behavior: 'smooth' });
                        }
                    }
                }}> Next Page</p>
            </div>)

            return final;
        }
        else return <p>Please try another search</p>
    }

    return (
        <>
            <h1>Check Stock</h1>
            <p>Enter a part number or keywords to search.</p>
            <div className="search-bar">
                <input type="text" onChange={(e) => { setSearch({ ...search, term: e.target.value }) }} onKeyDown={onKeyDown} />
                <button onClick={onSearch}>Search</button>
            </div>
            {search.status === "searching" && (
                <div className="loading" style={{ maxWidth: "40px" }}>
                    <p>Search in progress... </p>
                    <BarLoader color="#A91108" height={"2px"} />
                </div>

            )}
            {(search.last && search.status === "done") && (
                <>
                    <hr />


                    <div className="search-results basic">

                        <div className="search-results__stats" id="searchTop">
                            <p> {results.length} Search Results for "{search.last}"</p>
                            <p>Results {resultIndex + 1}-{resultIndex + resultsPerPage} of {results.length} </p>
                            <div className="pagination-selector">
                                <span>Results per page</span>
                                <select onChange={(e) => setResultsPerPage(parseInt(e.target.value))} selected={resultsPerPage}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                </select>
                            </div>
                        </div>
                        {resultsPerPage > 10 &&
                            <div key="pagination" className="pagination">
                                <p className={`prev ${resultIndex > 0 ? '' : "disabled"}`} onClick={() => {

                                    if (resultIndex >= resultsPerPage) {
                                        setResultIndex(resultIndex - resultsPerPage);
                                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                                            let top = document.getElementById("searchTop").offsetTop;
                                            console.log(top);
                                            window.scrollTo({ top: top, behavior: 'smooth' });
                                        }
                                    }


                                }}> Previous Page</p>
                                <p className={`next ${resultIndex + resultsPerPage < results.length ? '' : "disabled"}`} onClick={() => {

                                    if (resultIndex + resultsPerPage < results.length) {
                                        setResultIndex(resultIndex + resultsPerPage);
                                        if (window.innerWidth < 769 || resultsPerPage > 10) {
                                            let top = document.getElementById("searchTop").offsetTop;
                                            console.log(top);
                                            window.scrollTo({ top: top, behavior: 'smooth' });
                                        }
                                    }
                                }}> Next Page</p>
                            </div>
                        }
                        {renderSearchResults()}
                    </div>
                </>
            )}
            {search.status === "error" && (
                <div className="error">
                    <p> {search.message}</p>
                </div>
            )}
        </>
    )
}

export default StockCheck;