import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import toast from 'react-hot-toast';
import NotificationToast from '../NotificationToast';


const FormErrorNotification = () => {
    const { isValid, isValidating, isSubmitting } = useFormikContext()

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            toast.custom(
                (t) => (
                    <NotificationToast header={'Form Validation Failed'} body={"Please check your form inputs for errors."} type="danger" toastObject={t} />
                ), { duration: 4000, id: "validation-failed"}
            );
        }
    }, [isSubmitting, isValid, isValidating])

    return null
}

export default FormErrorNotification