import { useNavigate } from "react-router-dom";


const OrderSaved = () => {


    const navigate = useNavigate();

    return (
        <div>
            <h1> Order Saved</h1>
            <p>You order has been saved! Please note that you can only have a maximum of 5 saved orders.</p>
            <button className="button button-primary" onClick={() => { navigate("/saved-orders") } }>View Saved Orders</button>
          
        </div>
    )

}

export default OrderSaved;