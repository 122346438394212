import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AppContext from "../context/AppContext";




const Admin = () => {

    let context = useContext(AppContext);



    return (
        <div className="user-management">
            <h1>User Management</h1>
            <h2>Add New Users</h2>
            <Link to="/createUser" className="button button-primary" >Add New</Link>
            <h2>Manage Existing Users</h2>
            <div className="button-group">
                <Link to="/manageUser/dealers" className="button button-primary" >Dealers</Link>
                {(context.claims.role === "superUser" || context.claims.role === "admin") && (
                <Link to="/manageUser/sales" className="button button-primary">Sales Representatives</Link>
                )}
                {context.claims.role === "superUser" && (
                    <>
                        <Link to="/manageUser/administrators" className="button button-primary" >Administrators</Link>
                    </>
                )}
            </div>
        </div>
    )
}

export default Admin;