import { useContext, useState, useEffect } from "react"
import { Navigate } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import AppContext from "../context/AppContext"

const AlternateRoute = (props) => {
    const Component = props.component;
    const AlternateComponent = props.alternateComponent;
    const context = useContext(AppContext);
    const {isLoggedIn} = context;

    let loggedIn = isLoggedIn ;

    const jwt = document.cookie.split('; ').find(row => row.startsWith('jwt='));

    if (jwt === undefined ) {
        loggedIn = false
    }
    


    switch (loggedIn) {
        case true: {
            return <Component />
        }
        case false: {
            return <AlternateComponent />
        }
        default: {
            return (
                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                <p style={{flexBasis: "100%"}}> Loading... </p>
                <BarLoader />
                </div>
            )
        }
    }



}

export default AlternateRoute;