import { useRef } from "react";
import { Link } from "react-router-dom";


const LinkWrapper = ({ 
    children,
    link, 
    label, 
    onClick, 
    wrapperClass = "", 
    containerClass = "", 
    linkClass = "", 
    iconClass = "", 
    wrapperStyles = {}, 
    containerStyles = {}, 
    linkStyles = {},
    iconStyles = {},
    position = "left", 
}) => {

    const linkRef = useRef();

    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) {
            onClick();
        }
    }

    return (
        <div className={`link-wrapper ${wrapperClass}`} style={wrapperStyles}>
            <p className={`link-container ${containerClass}`} style={containerStyles} onClick={ () => {
                linkRef.current.click();
            }}>
                {(position === "left" && children) && (<Link onClick={handleClick} to={link} className={`icon ${iconClass}`} style={iconStyles}> {children} </Link>)}
                <Link onClick={handleClick} to={link} className={linkClass} style={linkStyles} ref={linkRef}> {label}</Link>
                {(position === "right" && children) && (<Link onClick={handleClick} to={link} className={`icon ${iconClass}`} style={iconStyles}> {children} </Link>)}
            </p>
        </div>
    )
}

export default LinkWrapper;