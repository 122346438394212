import { createSlice, current, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api';


export const fetchDataAndAddtoCart = createAsyncThunk('cart/fetchData', async ({ id, customer_number, quantity, note }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const productInCart = state.cart.products.find((product) => product.id === id);

    if (!productInCart) {
        console.log(id);
        try {
            const response = await api.post('/item-details', {
                product: id,
                customer_number: customer_number
            });
            console.log(response.data);
            
            const productData = response.data.item;


            productData.dealer_price = response.data.itemPrice?.price ?? response.data.itemPriceBreak?.dealer_price ?? response.data.item.retail_price ;

            dispatch(addToCart({ id: id, quantity, note, item: productData, priceBreak: response.data.itemPriceBreak }));
        } catch (err) {

            return rejectWithValue({code: err.response.status, message: err.response.data.message});
        }
        
    } else {
        dispatch(addToCart({ id: id, quantity }));
    }

})


export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        products: []
    },
    reducers: {
        addToCart: (state, action) => {
            console.log('Start state:', current(state));

            let id = action.payload.id;

            let index = state.products.findIndex((object) => object.id === id);

            if (index === -1) {
                state.products.push(action.payload);
            } else {
                state.products[index].quantity = parseInt(state.products[index].quantity) + parseInt(action.payload.quantity);
            }


            console.log('End state:', current(state));

        },
        clearCart: (state) => {
            state.products = [];
        },
        removeFromCart: (state, action) => {
            console.log('Start state:', current(state));
            console.log(action);
            state.products = state.products.filter(obj => obj.id !== action.payload);


            console.log('End state:', current(state));

        },
        changeQuantity: (state, action) => {
            console.log('Start state:', current(state));
            console.log(action);
            let id = action.payload.id;
            let index = state.products.findIndex((object) => object.id === id);
            if (action.payload.quantity < 0 ) {
                return;
            }
            state.products[index].quantity = parseInt( action.payload.quantity );

            console.log('End state:', current(state));
        },
        changeNote: (state, action) => {
            //console.log('Start state:', current(state));
            console.log(action);
            let id = action.payload.id;
            let index = state.products.findIndex((object) => object.id === id);
            console.log(action.payload.note.length);
            if (action.payload.note.length < 91 ) {
                state.products[index].note = action.payload.note;
            }
            

            //console.log('End state:', current(state));
        },
        replaceCart: (state, action) => {
            console.log('Start state:', current(state));
            console.log(action);
            let newCart = action.payload;
            state.products = newCart;

            console.log('End state:', current(state));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDataAndAddtoCart.rejected), (state, action) => {
            throw action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { addToCart, clearCart, removeFromCart, changeQuantity, changeNote, replaceCart } = cartSlice.actions

export default cartSlice.reducer