import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { useEffect, useState } from "react";

const DeleteUnconfirmedOrder = () => {

    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            let result = await api.post("/unconfirmed-order", { id });
            console.log(result);
            setOrder(result.data.order);
        }

        fetchData();

    }, [])

    const deleteOrder = async (id) => {
        console.log(id);
        try {
            await api.post("deleteUnconfirmedOrder", { id });
            navigate("/open-orders");

        } catch (err) {
            console.log(err);
        }
    }

    if (order === null) {
        return (
            <p>Loading...</p>
        )
    }

    return (
        <div className="delete-saved-order">
            <h1> Delete Unconfirmed Order "{order.po_number}"?</h1>
            <p>Are you sure that you want to delete this unconfirmed order? This action cannot be undone.</p>
            <p>Note: Deleting an unconfirmed order does not prevent it from being processed by Southern. This is only to manually clear orders from the database.</p>
            <button className="button button-primary" onClick={() => { deleteOrder(order._id) } }>Delete</button>
          
        </div>
    )

}

export default DeleteUnconfirmedOrder;