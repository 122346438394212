import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MainContent = (props) => {

    const location = useLocation();  

    return(
        <main style={{paddingTop: props.headerHeight }} ref={props.mainRef} className={location.pathname.slice(1)}>
              {props.children}
        </main>
    )
}

export default MainContent;