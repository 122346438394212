import { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setReduxUser } from '../redux/userSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/sharp-solid-svg-icons'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import AsyncSelect from 'react-select/async'
import toast from 'react-hot-toast';
import NotificationToast from "./NotificationToast";

import api from "../api";

import AppContext from "../context/AppContext";
import NavLinkGroup from "./NavLinkGroup";
import { SELECT_STYLES } from "../constants/styles";
import { ROLES_ARRAY } from "../constants/roles";
import CartQuantity from "./CartQuantity";
import { clearCart } from "../redux/cartSlice";
import { useWindowDimensions } from "../hooks/useWindowDimensions";




const Navigation = () => {

    const context = useContext(AppContext);
    const dispatch = useDispatch();
    const reduxUser = useSelector(state => state.user.data);
    const navigate = useNavigate();

    const { width } = useWindowDimensions();

    const cartLinkRef = useRef();
    const homeLinkRef = useRef();

    const [d, setD] = useState(reduxUser.dealer_selector);

    useEffect(() => {
        if (context.claims !== undefined) {
            if (d) {
                if (reduxUser.dealer_selector !== null) {
                    if (reduxUser.dealer_selector.label !== d.label) {
                        console.log("mismatch!");
                        window.location.reload();
                    }
                }
               
            } else {
                if (reduxUser.dealer_selector !== null) {
                    setD({...reduxUser.dealer_selector});
                    window.location.reload();
                } else {
                    console.log("Dealer Reload Should probably happen");
                }
            }
            

        }
    }, [reduxUser]);


    if (context.claims === undefined) {
        toast.custom(
            (t) => (
                <NotificationToast header={'Navigation Failed!'} body={"Oops, something went wrong when we tried to load that page."} type="danger" toastObject={t} />
            ), { duration: 4000, id: "navigation-failed" }
        );

        return;// <Navigate to="/login" replace />
        //TODO Generate Toast letting user know navigation failed and why. 
        // Typically if context.claims is undefined, it means that the page is a 404.
    }

    let { role } = context.claims;

    let roleIndex = ROLES_ARRAY.indexOf(role);


    const [isOpen, setOpen] = useState(false);


    const links = [
        { to: '/stock', label: "Stock Check & New Orders" },
        { to: '/saved-orders', label: "Saved Orders" },
        { to: '/open-orders', label: "Open Orders" },
        { to: '/invoice-history', label: "Invoice History" },
        { to: '/finances', label: "Finances" }

    ]

    if (roleIndex >= 2) {
        links.push(
            { to: '/admin', label: "User Management" }
        )
    }

    // if ( reduxUser.dealer.suspended ) {
    //     links.shift();
    // }

    const onClick = () => {
        setOpen(false);
        return;
    }

    const renderLinks = () => {
        return links.map(({ to, label }) => {
            return (
                <li key={to}>
                    <NavLinkGroup to={to} label={label} onClick={onClick} />
                </li>
            )
        })
    }

    const getDealerSelectorOptions = async () => {
        try {
            let dealers = await api.post('/dealers', { role });

            dealers = dealers.data.dealers;

            let options = dealers.map(dealer => {
                return ({
                    value: dealer,
                    label: `${dealer.customer_number} (${dealer.name_company})`
                });

            })

            return Promise.resolve(options);

        } catch (err) {
            console.log(err);
        }

    }

    const dealerFilter = (option, inputValue) => {
        const labelMatches = option.label.toLowerCase().includes(inputValue.toLowerCase());
        return labelMatches;
    }

    const renderName = () => {

        if (role === "dealer") {
            return (<>#{reduxUser.dealer_number} {reduxUser.dealer.name_company}</>)
        } else {
            return (<>{reduxUser.name_first}</>)
        }

    }

    return (
        <div className={`main-nav ${role ? role : "none"}`}>

            {roleIndex === -1 && (

                <ul className="logged-out-menu">
                    <li><Link to="/stock">Stock Check</Link></li>
                    <li>
                        <FontAwesomeIcon icon={solid('user')} />
                        <Link to="/login">Login</Link>
                    </li>
                </ul>
            )}

            <>
                {roleIndex >= 0 && (
                    <>
                        {width < 1024 && (
                            <div className="mobile-nav-open-container">
                                <FontAwesomeIcon icon={solid("bars")} size="2x" color="#fff" onClick={() => { setOpen(true) }} className="mobile-nav-open" />
                            </div>
                        )}
                        <nav className={`main-nav-container ${isOpen ? "open" : ''}`}>
                            {width <= 1024 && (
                                <>
                                    <FontAwesomeIcon icon={solid("xmark")} color="#fff" onClick={() => { setOpen(false) }} className="mobile-nav-close" />
                                    {window.innerWidth < 768 && (
                                        <div className="return">
                                            <p><a href={`https://${process.env.REACT_APP_DOMAIN}/`}>Return to Online Catalog</a></p>
                                            <FontAwesomeIcon icon={faArrowRightFromBracket} color="#fff" className="mobile-nav-catalog" />
                                        </div>
                                    )}
                                </>
                            )}
                            <ul className={`${role}-menu`}>
                                {renderLinks()}
                            </ul>
                        </nav>
                        <div className="controls">
                            {roleIndex >= 1 && (
                                <AsyncSelect
                                    className="dealer-selector"
                                    loadOptions={getDealerSelectorOptions}
                                    filterOption={dealerFilter}
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={d !== null ? d : undefined}
                                    styles={SELECT_STYLES}
                                    isClearable={true}
                                    onChange={async (e) => {
                                        console.log("Selector Change")
                                        console.log(e);
                                        if (e === null) {
                                            console.log("Selector Cleared!");
                                            await dispatch(setReduxUser({ dealer: {}, dealer_number: null, dealer_selector: null }))
                                        } else {
                                            await dispatch(setReduxUser({ dealer: e.value, dealer_number: e.value.customer_number, dealer_selector: e }));
                                        }
                                        await dispatch(clearCart());
                                        window.location.reload();
                                    }}

                                />
                            )}
                            <ul>
                                <li onClick={() => {
                                    homeLinkRef.current.click();
                                }}>
                                    <FontAwesomeIcon icon={solid('user')} color="#fff" />
                                    <Link to="/" className={role === "dealer" ? "name" : ""} ref={homeLinkRef}>
                                        {renderName()}
                                    </Link>
                                </li>
                                <li onClick={() => {
                                    cartLinkRef.current.click();
                                }}>
                                    <FontAwesomeIcon icon={solid("cart-shopping")} color="#fff" />
                                    <Link to="/cart" ref={cartLinkRef}><CartQuantity /></Link>
                                </li>
                            </ul>
                        </div>
                    </>
                )}
            </>

        </div>
    )
}

export default Navigation;