import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import NotificationToast from '../components/NotificationToast';
import api from '../api';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const ForgotPassword = () => {

    const [status, setStatus] = useState("start");

    const resetValidation = Yup.object().shape({
        email: Yup.string().email('Invalid Email').required("Email Address is Required."),
    });



    if ( status === "done") {
        return(
            <div className='form-container'> 
            <h1>Password Reset</h1>
            <p>Thanks! If your email address matches an account that we have on file, you will receive an email with a new temporary password.</p>
            <Link to="/login" className='button button-primary'>Return to Login Page</Link>
            </div>
        )
    }

    return (
        <div className='form-container'>
            <h1>Reset Password</h1>
            <p>
                Enter your email address below and we will email you a link to reset your password.
            </p>
            <Formik
                    initialValues={{ username: "", password: "" }}
                    validationSchema={resetValidation}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                           await api.post('/requestPasswordLink', { email: values.email});
                           setStatus("done");
                        } catch (err) {
                            console.log(err);
                            toast.custom(
                                (t) => (
                                    <NotificationToast header={'Oops.'} body={"An Unexpected Error Occurred."} type="danger" toastObject={t} />
                                ), { duration: 4000, id: "login-failed"}
                            );
                        }

                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <>
                        <Form>
                            <div className="field-container">
                                <label htmlFor="email">Email Address:</label>
                                <ErrorMessage name="email" component="div" className='error'/>
                                <Field type="text" name="email" />
                                
                            </div>
                            <button type="submit" disabled={isSubmitting}>
                                Reset
                            </button>
                            
                        </Form>
                        </>
                    )}
                </Formik>
        </div>
    )
}

export default ForgotPassword;