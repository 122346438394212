
import LinkWrapper from "../components/LinkWrapper";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { clearCart } from "../redux/cartSlice";


const OrderConfirmation = () => {

    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(clearCart());
    }, []);

    return(
        <div>
        <h1> Order Confirmation</h1>
        <p>Thank you for your order!</p>
        <LinkWrapper link="/" containerClass="button button-primary" label="Return to Dealer Home Page"/>
        </div>
    )
}

export default OrderConfirmation;