export const SELECT_STYLES = {
  control: (provided, state) => {
    return({
      ...provided,
      minHeight: '0',
      height: 'auto',
      boxShadow: 'none',
      zIndex: 200,
      boxShadow: state.hasValue ? "0px 0px 25px #000" : ''
    })
  },
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    backgroundColor: state.isSelected ? '#A91108' : '',

  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '16px',
    zIndex: 300,
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 2px"
  }),
  indicatorContainer: () => ({
    padding: "0"
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '16px'
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px'
  })
}

export const SELECT_STYLES_ALT = {
  control: (provided) => ({
    ...provided,
    minHeight: '0',
    height: 'auto',
    boxShadow: 'none',
    zIndex: 200,
    border: '1px solid #000',
    borderColor: '#000',
    padding: '2px'

  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    backgroundColor: state.isSelected ? '#A91108' : '',
    textAlign: "left"
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '16px',
    zIndex: 300,
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 2px",
    color: "#A91108"
  }),
  indicatorContainer: () => ({
    padding: "0",
    color: "#A91108"
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '16px',
    textAlign: "left"
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px',
    textAlign: "left"
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
    textAlign: "left"
  }),
  container: (provided) => ({
    ...provided,
    flexBasis: '100%',
  })
}