import { useEffect, useState, useContext } from "react";
import api from "../api";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setReduxUser } from '../redux/userSlice';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";

import AppContext from "../context/AppContext";

const DealerList = () => {

    const [dealers, setDealers] = useState(null);
    const [direction, setDirection] = useState({ number: true, name: true, login: true, date: true }); // TRUE MEANS ASCENDIND, FALSE MEANS DECENDING
    const { width } = useWindowDimensions();
    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let  context = useContext(AppContext);


    useEffect(() => {
        const getData = async () => {

            let dealerResult;
            let userResult;

            



            if (id === "house") {
                if (context.claims.role !== "admin" && context.claims.role !== "superUser") {
                    toast.custom(
                        (t) => (
                            <NotificationToast header={'Permission Denied'} body={"Oops, something went wrong when we tried to load that page."} type="danger" toastObject={t} />
                        ), { duration: 4000, id: "permission-denied" }
                    );
                    navigate("/");
                }
                console.log("Must Handle House Users");
                let sUserResult = await api.post('/users', { type: "sales" });

                let repNumbers = sUserResult.data.users.map(item => parseInt(item.rep_number));
                console.log(repNumbers);
                dealerResult = await api.post('/house-dealers', { rep_numbers: repNumbers });
                userResult = await api.post('/users', { type: "dealers" });

            } else {
                dealerResult = await api.post('/dealers', { id: id });
                userResult = await api.post('/users', { type: "dealers", as: id });
            }


            let dealerObject = dealerResult.data.dealers.reduce((acc, curr) => {
                acc[curr.customer_number] = curr;
                return acc;
            }, {});

            let userObject = userResult.data.users.reduce((acc, curr) => {
                acc[curr.dealer_number] = curr;
                return acc;
            }, {});

            let dealerNumberArray = dealerResult.data.dealers.reduce((acc, curr) => {
                acc.push(curr.customer_number);
                return acc;
            }, []);

            let orders = await api.post('/bulk-dealer-recent-orders', { dealers: dealerNumberArray });



            //console.log(merged);

            for (let key in userObject) {
                if (dealerObject[key]) {
                    dealerObject[key] = {
                        ...dealerObject[key],
                        ...userObject[key]
                    }
                }
            }

            for (let key in orders.data.orders) {
                if (dealerObject[key]) {
                    dealerObject[key] = {
                        ...dealerObject[key],
                        order: orders.data.orders[key][0]
                    }
                }
            }


            let dealersArray = Object.values(dealerObject);

            console.log(dealersArray.length);
            console.log(dealerResult.data.dealers.length);

            console.log(dealersArray);
            console.log(dealersArray.length);
            setDealers(dealersArray);

        }

        getData();
    }, []);

    const renderLastLogin = (dealer) => {

        if (dealer.lastLogin) {
            return dayjs(dealer.lastLogin).format('M/D/YY');
        }
        else if (dealer.accountCreated) {
            return "Never";
        }
        return "N/A";
    }

    const renderLastOrderLink = (dealer) => {
        if (dealer.order) {
            if (dealer.order.type === "invoicehistory") {
                return <Link to={`/invoice-history/${dealer.order._id}`} onClick={async (e) => {
                    await dispatch(setReduxUser({ dealer: dealer, dealer_number: dealer.customer_number, dealer_selector: { value: { _id: dealer._id, customer_number: dealer.customer_number }, label: `${dealer.customer_number} (${dealer.name_company})` } }));
                }}>{dealer.order.po_number === "" ? "Unnamed Order" : dealer.order.po_number}</Link>
            } 

            return <Link to={`/open-order/${dealer.order.type}/${dealer.order._id}`} onClick={async (e) => {
                await dispatch(setReduxUser({ dealer: dealer, dealer_number: dealer.customer_number, dealer_selector: { value: { _id: dealer._id, customer_number: dealer.customer_number }, label: `${dealer.customer_number} (${dealer.name_company})` } }));

            }}>{dealer.order.po_number === "" ? "Unnamed Order" : dealer.order.po_number}</Link>
        }
        return "N/A"
    }

    const renderLastOrderDate = (dealer) => {
        return dayjs(dealer.order?.order_date).format('M/D/YY') ?? "N/A";
    }

    const renderDealers = () => {


        if (width < 768) {
            return dealers.map((dealer, index) => {
                return (
                    <div key={index} className="dealer">
                        <div className="result number">
                            <div className="title">Dealer #:</div>
                            <div className="result__data number__data">{dealer.customer_number}</div>
                        </div>
                        <div className="result name">
                            <div className="title">Dealer Name:</div>
                            <div className="result__data name__data">{dealer.name_company}</div>
                        </div>
                        <div className="result address">
                            <div className="title">Default Shipping Address:</div>
                            <div className="result__data address__data">
                                <p>{dealer.address?.[9999]?.street},</p>
                                <p>{dealer.address?.[9999]?.city} {dealer.address?.[9999]?.state} {dealer.address?.[9999]?.postal}</p>
                            </div>

                        </div>
                        <div className="result last-login">
                            <div className="title">Last Login:</div>
                            <div className="result__data last-login__data">{renderLastLogin(dealer)}</div>
                        </div>
                        <div className="result last-order">
                            <div className="title">Last Order PO:</div>
                            <div className="result__data last-order__data">{renderLastOrderLink(dealer)}</div>
                        </div>
                        <div className="result last-date">
                            <div className="title">Date of Last Order:</div>
                            <div className="result__data last-date__data">{renderLastOrderDate(dealer)}</div>
                        </div>

                    </div>
                )
            })
        }

        return dealers.map((dealer, index) => {
            return (
                <div key={index} className="dealer">
                    <div className="result number">
                        <div className="result__data number__data">{dealer.customer_number}</div>
                    </div>
                    <div className="result name">
                        <div className="result__data name__data">{dealer.name_company}</div>
                    </div>
                    <div className="result address">
                        <div className="result__data address__data">
                            <p>{dealer.address?.[9999]?.street},</p>
                            <p>{dealer.address?.[9999]?.city} {dealer.address?.[9999]?.state} {dealer.address?.[9999]?.postal}</p>
                        </div>
                    </div>
                    <div className="result last-login">
                        <div className="result__data last-login__data">{renderLastLogin(dealer)}</div>
                    </div>
                    <div className="result last-order">
                        <div className="result__data last-order__data">{renderLastOrderLink(dealer)}</div>
                    </div>
                    <div className="result last-date">
                        <div className="result__data last-date__data">{renderLastOrderDate(dealer)}</div>
                    </div>


                </div>
            )
        })

    }

    const sort = (type) => {
        console.log(type);
        console.log(dealers);
        let newDealers = [...dealers];
        switch (type) {
            case "dealer": {
                newDealers.sort((a, b) => {
                    if (direction.number === true) {
                        return b.customer_number - a.customer_number
                    } else {
                        return a.customer_number - b.customer_number
                    }
                });
                setDirection({ ...direction, number: !direction.number });
                setDealers(newDealers);
                break;
            }
            case "name": {
                newDealers.sort((a, b) => {
                    if (direction.name === true) {
                        return b.name_company.localeCompare(a.name_company);
                    } else {
                        return a.name_company.localeCompare(b.name_company);
                    }
                });
                setDirection({ ...direction, name: !direction.name });
                setDealers(newDealers);
                break;
            }
            case "login": {
                newDealers.sort((a, b) => {

                    if (a.lastLogin === undefined && b.lastLogin === undefined) {
                        // Sort based on accountCreated: true before false
                        return (a.accountCreated === b.accountCreated) ? 0 : a.accountCreated ? -1 : 1;
                    }
                    if (a.lastLogin === undefined) return 1; // Place a at the end if lastLogin is undefined
                    if (b.lastLogin === undefined) return -1; // Place b at the end if lastLogin is undefined

                    if (direction.login === true) {
                        // Descending order by recency
                        return new Date(b.lastLogin) - new Date(a.lastLogin);
                    } else {
                        // Ascending order by recency
                        return new Date(a.lastLogin) - new Date(b.lastLogin);
                    }

                });
                setDirection({ ...direction, login: !direction.login });
                setDealers(newDealers);
                break;
            }
            case "date": {
                newDealers.sort((a, b) => {

                    if (a.order === undefined) return 1; // Place a at the end if undefined
                    if (b.order === undefined) return -1; // Place b at the end if undefined

                    // Parse dates using Day.js
                    const dateA = dayjs(a.order.order_date, "MM/DD/YY").valueOf();
                    const dateB = dayjs(b.order.order_date, "MM/DD/YY").valueOf();

                    if (direction.date === true) {
                        // Descending order by recency
                        return dateB - dateA;
                    } else {
                        // Ascending order by recency
                        return dateA - dateB;
                    }

                });
                setDirection({ ...direction, date: !direction.date });
                setDealers(newDealers);
                break;
            }
            default: {
                break;
            }
        }
    }

    if (dealers === null) {
        return (
            <p>Loading...</p>
        )
    }

    return (
        <div className="dealer-list">
            {id !== "house" && (
                <>
                    <h1>Dealer List</h1>
                    <p>Here is a list of your dealers.</p>
                </>
            )}
            {id === "house" && (
                <>
                    <h1>House Dealer List</h1>
                    <p>This is a list of all dealers with sales representatives that do not have a User account created.</p>
                </>

            )}
            <div className="dealer-container">
                {width >= 768 && (
                    <div className="dealer-header">
                        <div className="title number" onClick={() => { sort("dealer") }} >Dealer #: <FontAwesomeIcon icon={direction.number ? solid("chevron-down") : solid("chevron-up")} /></div>
                        <div className="title name" onClick={() => { sort("name") }}>Dealer Name: <FontAwesomeIcon icon={direction.name ? solid("chevron-down") : solid("chevron-up")} /></div>
                        <div className="title address" onClick={() => { sort("address") }}>Default Shipping Address:</div>
                        <div className="title last-login" onClick={() => { sort("login") }}>Last Login: <FontAwesomeIcon icon={direction.login ? solid("chevron-down") : solid("chevron-up")} /></div>
                        <div className="title last-order" onClick={() => { sort("po") }}>Last Order PO:</div>
                        <div className="title last-date" onClick={() => { sort("date") }}>Last Order Date: <FontAwesomeIcon icon={direction.date ? solid("chevron-down") : solid("chevron-up")} /></div>
                    </div>
                )}
                {renderDealers()}
            </div>
        </div>
    )
}

export default DealerList;