import { useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import { Link } from "react-router-dom";
import LinkWrapper from "../components/LinkWrapper";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const Home = () => {

    const context = useContext(AppContext);
    const { claims, onLogout } = context;
    const user = useSelector(state => state.user.data);
    const [lastOrder, setLastOrder] = useState(null);

    let address = {};

    if (user?.dealer?.address !== undefined) {
        address = user.dealer.address;

        let keys = Object.keys(address).filter(k => !isNaN(k)).sort((a, b) => b - a);
        if (keys.length > 0) {
            let nextLowestKey = keys[0]; // after sorting in descending order, the first key is the highest
            address = address[nextLowestKey];
        }
        else if (address['billing']) {
            address = address['billing'];
        }
        else {
            address = { street: "ERROR", city: "ERROR", state: "ERROR", postal: "ERROR" }
        }
    }

    useEffect(() => {
        const getData = async () => {
            if (claims.role === 'dealer') {
                try {

                    let lastOrder = await api.post("/latest-order");

                    setLastOrder(lastOrder.data.order);
                    console.log(lastOrder);
                }
                catch (err) {
                    console.warn(err);
                    setLastOrder(null);
                }
            }


        }

        getData();
    }, [])




    return (
        <div className="home">
            <div className="info">

                {claims.role === "dealer" && (
                    <>
                        <p className="bold">Account #{user.dealer_number}</p>
                        <p className="bold" style={{ marginBottom: "-0.5rem" }}>{user.dealer.name_company}</p>
                        <p className="capitalize">{address.street}, {address.city} {address.state} {address.postal}</p>
                        <p>Your default shipping method: {user.dealer.default_shipping.long}</p>
                        <p>Your email address: {user.email}</p>
                        {lastOrder && lastOrder !== null && (
                            <p>Last order submitted: <strong><Link to={`/open-order/${lastOrder.type}/${lastOrder._id}`}>{lastOrder.po_number}</Link></strong> on <strong>{lastOrder.order_date}</strong></p>
                        )}

                    </>
                )}
                {claims.role === "sales" && (
                    <>
                        <p className="bold">Sales Rep #{user.rep_number}</p>
                        <p>Your email address: {user.email}</p>

                    </>
                )}
                {(claims.role === "sales" || claims.role === "admin" || claims.role === "superUser") && (
                    <>
                        <p className="bold">{user.name_first} {user.name_last}</p>
                        <p>Your email address: {user.email}</p>
                    </>
                )}


                <p>Last login: <strong>{dayjs(user.lastLogin).format("DD/MM/YY")}</strong></p>

                {claims.role === "dealer" && (
                    <>
                        <LinkWrapper link="/open-orders" label="Open Orders" />
                        <LinkWrapper link="/invoice-history" label="Invoice History" />
                    </>
                )}

                {claims.role === "sales" && (
                    <LinkWrapper link="/dealer-list" label="Dealer List" />
                )}

                {(claims.role === "admin" || claims.role === "superUser") && (
                    <>
                        <LinkWrapper link="/admin" label="User Management" />
                        <LinkWrapper link="/dealer-list/house" label="View House Dealer List" />
                        <LinkWrapper link="/dealer-list/" label="View Dealers List" />
                    </>
                )}
                {(claims.role === "superUser") && (
                    <>
                        <LinkWrapper link="/email-admin" label="Email File Delivery Admin" />
                    </>
                )}
                <LinkWrapper link="/change-password" label="Change Password" />
                <LinkWrapper link="/change-email" label="Change Email Address" />
                <LinkWrapper link="/#" label="Logout" onClick={() => { onLogout(true) }} />
            </div>
            {user.dealer.suspended === false && (
                <div className="home-order">
                    {user.dealer.new_dealer && (
                        <>
                            <FontAwesomeIcon icon={solid("circle-exclamation")} color={"#A91108"} size="3x" />
                            <p className="bold">As a new account, your first order must exceed $500. Continue below to place your initial order.</p>
                        </>
                    )}
                    <h2>Check Stock or Place an Order</h2>
                    <p>To check stock or search for parts to order, click the button below.</p>
                    <Link to="/stock" className="button button-primary">Stock Check & New Orders</Link>
                    <p>OR</p>
                    {!user.dealer.new_dealer && (
                        <Link className="button button-outline" to={`/lightspeed`}>Upload ADP-Lightspeed Order </Link>
                    )}
                    <Link className="button button-outline" to="/saved-orders">Resume Order</Link>

                </div>
            )}
            {user.dealer.suspended === true && (
                <div className="home-suspended">
                    <FontAwesomeIcon icon={solid("circle-exclamation")} color={"#A91108"} size="3x" />
                    {user.dealer.amount_due === 0 && (
                        <p className="bold">Your account is suspended. Please <a href="mailto:cs@southernms.com">contact us</a> for information and to restore access.</p>
                    )}
                    {user.dealer.amount_due !== 0 && (
                        <p className="bold">Your account is suspended. Please review the <Link to="/finances">Finances</Link> tab for more information and contact us to make a payment.</p>
                    )}

                </div>
            )}
        </div>
    )
}

export default Home;