import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import api from "../api";

import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ChangePassword = () => {


    const changePasswordValidation = Yup.object().shape({
        current_password: Yup.string().required("Required"),
        password: Yup.string().min(8, 'Password must be at least 8 characters long')
            .max(64, 'Password must be at most 64 characters long')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+().,<>%{}\]\[#^_\-=|:;"'\/~`])[A-Za-z\d@$!%*?&+().,<>%{}\]\[#^_\-=|:;"'\/~`]+$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            )
            .required('Password is required'),
        password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required.')
    })

    const [ status, setStatus ] = useState("start");

    if (status === "done") {
        return(
            <div>
                <h1>Password Changed</h1>
                <p>Thank you for changing your password.</p>
                <Link className="button button-primary" to="/">Return to Dealer home page</Link>
            </div>
        )
    }

    return (
        <div>
            <h1>Change Password</h1>
            <Formik
                initialValues={{ current_password: "", password: "", password_confirm: "" }}
                validationSchema={changePasswordValidation}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        let result = await api.post("userChangePassword", { password: values.password, current_password: values.current_password })
                        console.log(result);
                        setSubmitting(false);
                        
                        setStatus("done");
                    } catch (err) {
                        console.warn(err);
                        let message = err.response.data.message;
                        if (err.response.status === 403 ) {
                            message = "Invalid Password."
                        }
                        toast.custom(
                            (t) => (
                                <NotificationToast header={'Error'} body={message} type="danger" toastObject={t} />
                            ), { duration: 4000, id: "reset-failed" }
                        );

                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="field-container">
                            <label htmlFor="current_password">Current Password</label>
                            <ErrorMessage name="current_password" component="div" className='error' />
                            <Field type="password" name="current_password" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="password">New Password</label>
                            <ErrorMessage name="password" component="div" className='error' />
                            <Field type="password" name="password" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="password_confirm">Retype New Password</label>
                            <ErrorMessage name="password_confirm" component="div" className='error' />
                            <Field type="password" name="password_confirm" />
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ChangePassword;