import { Form, Formik, ErrorMessage, Field } from "formik";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import NotificationToast from "../components/NotificationToast";
import { useSelector } from "react-redux";

const LightSpeed = () => {

    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const user = useSelector(state => state.user.data);

    if (user.role !== 'dealer') {
        if (user.dealer_number === null) {
            return (
                <div>
                    <h1>Upload ADP-Lightspeed File</h1>
                    <p>No dealer selected. Please select a dealer to continue...</p>
                </div>
            )

        }
    }

    return (
        <div>
            <h1>Upload ADP-Lightspeed File</h1>
            <Formik
                initialValues={{ file: undefined }}
                validationSchema={Yup.object().shape({
                    file: Yup.mixed().required('File is required'),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        console.log(values);
                        console.log(file);
                        let reader = new FileReader();
                        reader.onload = (event) => {
                            const base64String = btoa(event.target.result);
                            const base64Object = { type: "lightspeed", b64: base64String }
                            navigate(`/finalize/${btoa((JSON.stringify(base64Object)))}`);
                        }
                        reader.readAsBinaryString(file);

                    } catch (err) {
                        console.log(err);
                    }

                    setSubmitting(false);
                }}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <>
                        <Form>
                            <div className="field-container">
                                <ErrorMessage name="file" component="div" className='error' />
                                <div className="input-container">
                                    <label htmlFor="file" className="custom-file-button">Choose File</label>
                                    <Field type="hidden" name="file" />
                                    <Field
                                        type="file"
                                        name="x"
                                        accept="text/plain"
                                        onChange={
                                            (event) => {
                                                try {
                                                    if (event.currentTarget.files[0].type !== "text/plain") {
                                                        console.warn("Invalid File Type!");
                                                        toast.custom(
                                                            (t) => (
                                                                <NotificationToast header={'Upload Failed!'} body={"Invalid File Type - Only text files are permitted."} type="danger" toastObject={t} />
                                                            ), { duration: 4000, id: "navigation-failed" }
                                                        );
                                                        return;
                                                    }
                                                    console.log(event.currentTarget.files[0])
                                                    setFile(event.currentTarget.files[0]);
                                                    setFieldValue('file', event.currentTarget.files[0].name);
                                                }
                                                catch (error) {
                                                    console.log(error);
                                                    toast.custom(
                                                        (t) => (
                                                            <NotificationToast header={'Upload Failed!'} body={"An unknown error occurred."} type="danger" toastObject={t} />
                                                        ), { duration: 4000, id: "navigation-failed" }
                                                    );
                                                    return;
                                                }

                                            }
                                        } />

                                </div>
                                {file && (<span>{file.name}</span>)}

                            </div>
                            <button type="submit" disabled={isSubmitting}>
                                submit
                            </button>

                        </Form>
                    </>
                )}
            </Formik>
        </div>

    )
}

export default LightSpeed;