import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from 'yup';
import { useState } from "react";
import { ReactAsyncSelect } from "../components/Formik/ReactSelect";
import { SELECT_STYLES_ALT } from "../constants/styles";
import { useSelector } from "react-redux";

import LinkWrapper from "../components/LinkWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from "react-router-dom";


const Checkout = () => {

    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    let { data } = useParams();



    const user = useSelector(state => state.user.data);

    try {
        data = JSON.parse(atob(data));
    } catch {
        data = {}
    }


    const initialValues = {
        shipping: data.shipping ?? 'default',
        po_number: data.po_number ?? dayjs(new Date()).format('YYMMDD HHMMss'),
        special_instructions: data.special_instructions ?? '',
        address: data.address === undefined ? true : {
            value: { ...data.address },
            label: `${10000 - data.address.address_id}. ${data.address.name}`
        },

    };


    const validationSchema = Yup.object().shape({
        shipping: Yup.string().required('Required'),
        po_number: Yup.string()
            .required('Required')
            .max(16, 'Max 16 Characters'),
        address: Yup.mixed().test('isObject', 'Required', (value) => {
            return typeof value === 'object';
        }),
        special_instructions: Yup.string().max(31, 'Max 31 Characters'),
    });



    const handleSubmit = (values) => {
        if (values.address.value) {
            console.log("Resumed!");
            values.address = values.address.value;
        }
        navigate(`/order-summary/${btoa(JSON.stringify(values))}`);
    };

    const getAddressOptions = async () => {
        try {

            let address = user.dealer.address;

            let keys = Object.keys(address).sort((a, b) => parseInt(b) - parseInt(a));

            console.log(keys);

            let i = keys.indexOf('billing');

            if (i !== -1) {
                if (keys.length === 1) {
                    address['9999'] = address['billing'];
                    keys[0] = '9999';
                } else {
                    keys = keys.filter(item => item !== 'billing');
                }
            }
        
            console.log(keys);

            let options = keys.map((key, index) => {

            let name = address[key].name;
            let street = address[key].street

            let id = parseInt(address[key].address_id.trim());

            return (
                {
                    value: { ...address[key] },
                    label: `${index + 1}. ${name} (${street})`
                }
            )
        })

        return Promise.resolve(options);


    } catch (err) {
        console.log(err);
    }

}

const changeFormStep = async (validateForm, setFieldTouched, touched) => {
    const validationErrors = await validateForm();
    if (step === 1) {
        const fields = ['shipping', 'po_number', 'special_instructions']
        console.log(validationErrors);
        const hasValidationError = fields.some(field => validationErrors[field]);

        if (hasValidationError) {
            fields.forEach(field => {
                if (validationErrors[field]) {
                    setFieldTouched(field, true);
                }
            });
        } else {
            console.log(touched);
            setFieldTouched("address", false),
                setStep(2);
        }
    }
}

return (
    <div className="checkout-container">
        <h1>Place Order</h1>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values, errors, touched, validateForm, setFieldTouched }) => (
                <Form>
                    <div className={`section-1 ${step === 1 ? "active" : "hidden"}`}>
                        <div className="field-container ship-via">
                            <label>Ship Via</label>
                            <ErrorMessage name="shipping" component="div" className="error" />
                            <div className="input-container">
                                <div className="radio-container">
                                    <Field type="radio" name="shipping" value="default" id="default" aria-labelledby="default-label" />
                                    <label htmlFor="default"></label>
                                    <span id="default-label">{user.dealer.default_shipping.long}</span>
                                </div>
                                {user.dealer.default_shipping.long !== "WILL CALL" && (
                                    <div className="radio-container">
                                        <Field type="radio" name="shipping" value="will-call" id="will-call" aria-labelledby="will-call-label" />
                                        <label htmlFor="will-call"></label>
                                        <span id="will-call-label">Will Call</span>
                                    </div>
                                )}
                            </div>


                        </div>
                        <div className="field-container">
                            <label htmlFor="po_number">Purchase Order Number</label>
                            <ErrorMessage name="po_number" component="div" className="error" />
                            <Field type="text" name="po_number" id="po_number" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="special_instructions">Special Instructions</label>
                            <ErrorMessage name="special_instructions" component="div" className="error" />
                            <Field type="text" name="special_instructions" id="special_instructions" />
                        </div>
                        <div className="button-group">
                            <LinkWrapper link="/cart" label="Back" position="left" containerClass="button button-secondary"><FontAwesomeIcon icon={solid("chevron-left")} /> </LinkWrapper>
                            <LinkWrapper link="#" label="Next" position="right" containerClass="button button-primary" onClick={() => changeFormStep(validateForm, setFieldTouched, touched)}><FontAwesomeIcon icon={solid("chevron-right")} /> </LinkWrapper>
                            <Link to="/save-cart" className="button button-outline">Save Order for Later</Link>
                        </div>

                    </div>
                    <div className={`section-2 ${step === 2 ? "active" : "hidden"}`}>
                        <div className="field-container">
                            <label htmlFor="address">Select a Ship-to Address</label>
                            <ErrorMessage name="address" component="div" className='error' />
                            <Field
                                component={ReactAsyncSelect}
                                id="address"
                                name="address"
                                styles={SELECT_STYLES_ALT}
                                def
                                asyncOptions={{ loadOptions: getAddressOptions, cache: true, defaultOptions: true, default: initialValues?.address }}
                            />

                        </div>
                        <div className="address-data">
                            <p className="bold">{values.address?.value?.name ?? values.address.name}</p>
                            <p>{values.address?.value?.street ?? values.address.street}</p>
                            <p>{values.address?.value?.city ?? values.address.city} {values.address?.value?.state ?? values.address.state} {values.address?.value?.postal ?? values.address.postal}</p>
                        </div>
                        <div className="button-group">
                            <LinkWrapper link="#" label="Back" position="left" containerClass="button button-secondary" onClick={() => setStep(1)}><FontAwesomeIcon icon={solid("chevron-left")} /> </LinkWrapper>
                            <button type="submit">Next <FontAwesomeIcon icon={solid("chevron-right")} /> </button>
                            <Link to="/save-cart" className="button button-outline">Save Order for Later</Link>
                        </div>

                    </div>

                </Form>
            )}
        </Formik>
    </div>
)
}

export default Checkout;