import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import LinkWrapper from "../components/LinkWrapper";
import { useEffect } from "react";

const OrderSummary = () => {

    const user = useSelector(state => state.user.data);
    const cart = useSelector(state => state.cart.products);
    const { width } = useWindowDimensions();
    let { data } = useParams();

    let base64 = data;
    let base64Obj = btoa(JSON.stringify({ type: "csv", b64: data }));
    try {
        data = JSON.parse(atob(data));
    } catch (err) {
        data = {};
    }

    const renderAccordion = () => {

        return cart.map((product, index) => {

            return (
                <AccordionItem key={index}>
                    <AccordionItemState>
                        {({ expanded }) => (
                            <>
                                <AccordionItemHeading className={expanded ? "accordion__heading expanded" : "accordion__heading"}>
                                    <AccordionItemButton>
                                        <span>{product.item.item_number}</span>
                                        {expanded ? (
                                            <FontAwesomeIcon icon={solid("minus")} color="#A91108" />
                                        ) : (
                                            <FontAwesomeIcon icon={solid("plus")} color="#A91108" />
                                        )}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="part-description result">
                                        <p className="part-description__data result__data">{product.item.item_description}</p>
                                        {product.item.manufacturer_number && (
                                            <p className="part-manufacturer__data result__data">MFG#: {product.item.manufacturer_number}</p>
                                        )}
                                    </div>
                                    <div className="note result">
                                        <p className="title">Note (Optional)</p>
                                        <p className="note__data result__data">{product.note}</p>
                                    </div>
                                    <div className="price result">
                                        <p className="title">Suggested Retail Price</p>
                                        <p className="price__data result__data">{product.item.retail_price.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    <div className="dealer-price result">
                                        <p className="title">Dealer Price</p>
                                        <p className="dealer-price__data result__data">{product.item.dealer_price?.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? product.item.retail_price.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    <div className="quantity result">
                                        <p className="title">Quantity</p>
                                        <p className="quantity__data result__data">{product.quantity}</p>
                                    </div>
                                    <div className="subtotal result">
                                        <p className="title">Subtotal</p>
                                        <p className="subtotal__data result__data">{product.item.dealer_price ? (product.item.dealer_price * product.quantity).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (product.item.retail_price * product.quantity).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                </AccordionItemPanel>
                            </>
                        )}
                    </AccordionItemState>
                </AccordionItem>

            )
        })
    }


    const renderCart = () => {

        if (width < 1024) {
            return (
                <Accordion allowMultipleExpanded allowZeroExpanded className="cart-accordion">
                    {renderAccordion()}
                </Accordion>
            )
        }


        return cart.map((product) => {
            return (
                <div key={product.id} className="result__container">
                    <div className="part-number result">
                        <p className="part-number__data result__data">{product.item.item_number}</p>
                    </div>
                    <div className="part-description result">
                        <p className="part-description__data result__data">{product.item.item_description}</p>
                        {product.item.manufacturer_number !== "" && (
                            <p className="part-manufacturer__data result__data">MFG#: {product.item.manufacturer_number}</p>
                        )}
                    </div>
                    <div className="note result">
                        <p className="note__data result__data">{product.note}</p>
                    </div>
                    <div className="price result">
                        <p className="price__data result__data">{product.item.retail_price.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>

                    <div className="dealer-price result">
                        <p className="dealer-price__data result__data">{product.item.dealer_price?.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? product.item.retail_price.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div className="quantity result">
                        <p className="quantity__data result__data">{product.quantity}</p>
                    </div>
                    <div className="subtotal result">
                        <p className="subtotal__data result__data">{product.item.dealer_price ? (product.item.dealer_price * product.quantity).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (product.item.retail_price * product.quantity).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                </div>
            )
        })
    }

    const renderTotal = () => {
        let totalPrice = 0;

        cart.forEach(product => {
            let price = product.item.dealer_price !== undefined ? product.item.dealer_price : product.item.retail_price;
            totalPrice += price.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) * product.quantity;
        });

        return totalPrice;
    }

    const total = renderTotal();

    useEffect( () => {
        if (cart.length === 0) {
            toast.custom(
                (t) => (
                    <NotificationToast header={'Empty Cart'} body={"Order cannot be submitted with an empty cart."} type="danger" toastObject={t} />
                ), { duration: 4000, id: "empty-cart"}
            );
        }
    }, [])

    return (
        <div className="order-summary">
            <h1>Order Summary</h1>
            <p className="bold">Account #{user.dealer_number}</p>
            <p className="bold company-name">{user.dealer.name_company}</p>
            <p className="po">Purchase Order Number: #{data.po_number}</p>
            {data.special_instructions && (
                <p className="special-instructions">Special Instructions: {data.special_instructions} </p>
            )}

            <div className="cart-summary">
                {width >= 1024 && (

                    <div className="cart-header">

                        <div className="part-number header-item">Part Number:</div>
                        <div className="part-description header-item">Part Description:</div>
                        <div className="note header-item">Note (Optional):</div>
                        <div className="price header-item">Suggested Retail Price:</div>
                        <div className="dealer-price header-item">Dealer Price:</div>
                        <div className="quantity header-item">Quantity:</div>
                        <div className="subtotal header-item">Subtotal:</div>
                    </div>
                )}
                <div className="cart-body">
                    {renderCart()}
                </div>
            </div>
            <div className="total-summary">
                <div>
                    <p className="title">Total Dealer Price</p>
                    <p className="total">${total.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
                <div>
                    <p className="title">Shipping Price</p>
                    {total < user.dealer.freight_minimum && (
                        <div className="shipping-container">
                            <p className="shipping-blurb">Shipping charge will be calculated at time of shipping</p>
                            <p className="shipping-teaser">You need <span>${(user.dealer.freight_minimum - total).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> more for free shipping</p>
                        </div>
                    )}
                    {total >= user.dealer.freight_minimum && (
                        <div className="shipping-container">
                            <p className="shipping-blurb">Free Shipping</p>
                        </div>
                    )}
                </div>

            </div>
            <div className="shipping-summary">
                <p className="title standalone">Ship Via</p>
                <div className="shipping-container">
                    <p>{data.shipping === "default" ? user.dealer.default_shipping.long : "Will Call"}</p>
                </div>
                <p className="title standalone">Ship-to-Address</p>
                <div className="address-container">
                    <p className="name">{user.dealer.address[data.address.address_id].name}</p>
                    <p>{user.dealer.address[data.address.address_id].street.toLowerCase()}</p>
                    <p>{user.dealer.address[data.address.address_id].city.toLowerCase()} {user.dealer.address[data.address.address_id].state} {user.dealer.address[data.address.address_id].postal} </p>
                </div>
            </div>
            {user.dealer.suspended && (
                <>
                    <div className="new-account-container">
                        <FontAwesomeIcon icon={solid("circle-exclamation")} size={"2x"} color="#A91108" />
                        <p className="bold">Your account is suspended. Please contact us for information and to restore access</p>
                    </div>
                    <div className="button-group">
                        <LinkWrapper link={`/checkout/${base64}`} label="Back" position="left" containerClass="button button-secondary" ><FontAwesomeIcon icon={solid("chevron-left")} /> </LinkWrapper>
                        <LinkWrapper link={`#`} label="Submit" position="right" containerClass="button button-secondary no-hover" ><FontAwesomeIcon icon={solid("chevron-right")} /> </LinkWrapper>
                        <Link to="/save-cart" className="button button-outline" >Save Order for Later</Link>
                    </div>
                </>
            )}
            {(user.dealer.new_dealer && total <= 500) && (
                <>
                    <div className="new-account-container">
                        <FontAwesomeIcon icon={solid("circle-exclamation")} size={"2x"} color="#A91108" />
                        <p className="bold">As a new account, your first order must exceed $500. You need to add more parts before you can submit this order.</p>
                    </div>
                    <div className="button-group">
                        <LinkWrapper link={`/checkout/${base64}`} label="Back" position="left" containerClass="button button-secondary" ><FontAwesomeIcon icon={solid("chevron-left")} /> </LinkWrapper>
                        <LinkWrapper link={`#`} label="Submit" position="right" containerClass="button button-secondary no-hover" ><FontAwesomeIcon icon={solid("chevron-right")} /> </LinkWrapper>
                        <Link to="/save-cart" className="button button-outline" >Save Order for Later</Link>
                    </div>
                </>
            )}
            {((!user.dealer.new_dealer || total >= 500) && !user.dealer.suspended) && (
                <div className="button-group">
                    <LinkWrapper link={`/checkout/${base64}`} label="Back" position="left" containerClass="button button-secondary" ><FontAwesomeIcon icon={solid("chevron-left")} /> </LinkWrapper>
                    {cart.length === 0 && (
                       <LinkWrapper link={`#`} label="Submit" position="right" containerClass="button button-secondary no-hover" ><FontAwesomeIcon icon={solid("chevron-right")} /> </LinkWrapper>
                    )}
                    {cart.length !== 0 && (
                        <LinkWrapper link={`/${user.role !== "dealer" ? `dealer-order-confirm/${base64Obj}` : `finalize/${base64Obj}`}`} label="Submit" position="right" containerClass="button button-primary" ><FontAwesomeIcon icon={solid("chevron-right")} /> </LinkWrapper>
                    )}
                    <Link to="/save-cart" className="button button-outline" >Save Order for Later</Link>
                </div>
            )}

        </div>
    )
}

export default OrderSummary;