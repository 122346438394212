import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LinkWrapper from "../components/LinkWrapper";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import api from "../api";
import { setReduxUser } from "../redux/userSlice";

const CreateOrder = () => {

    const user = useSelector(state => state.user.data);
    const cart = useSelector(state => state.cart.products);
    const [status, setStatus] = useState("loading");
    const [error, setError] = useState(undefined);
    const dispatch = useDispatch();

    const { data } = useParams();

    const isMounted = useRef(false);

    const navigate = useNavigate();


    useEffect(() => {

        const sendOrder = async () => {
            console.log("Send Order Started");
            let decoded = JSON.parse(atob(data));
            console.log(decoded);
            switch (decoded.type) {
                case "csv": {
                    let totalPrice = 0;

                    cart.forEach(product => {
                        let price = product.item.dealer_price !== undefined ? product.item.dealer_price : product.item.retail_price;
                        totalPrice += price.toLocaleString("en-US", { style:"currency", currency:"USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) * product.quantity;
                    });

                    if (user.dealer.new_dealer === true && totalPrice <= 500) {
                        // navigate("/");
                        return;
                    }
                    try {
                        let order = { cart: cart, data: { ...user, ...JSON.parse(atob(decoded.b64)) } }
                        await api.post("/createOrder", { order: order });
                        setStatus("done");

                        break;
                    } catch (err) {
                        console.warn(err)
                        setStatus("error");
                        break;
                    }
                }
                case "lightspeed": {
                    try {
                        console.log(decoded);
                        await api.post("/adpOrder", { order: { data: { ...user } }, file: decoded.b64 });
                        setStatus("done");
                        break;
                    } catch (err) {
                        console.error(err);
                        setStatus("error");
                        setError(err);
                        break;
                    }
                }
            }




        }



        if (!isMounted.current) {
            // Run the API call only when the component mounts
            console.count("Only Once");
            sendOrder();

            isMounted.current = true;
        }

        return () => {
            console.log("Component unmounted.");
        };
    }, [])
    if (status === "loading") {
        return (
            <div>Loading...</div>
        )
    }
    if (status === "error") {
        if (error !== undefined) {
            if (error.response.data.message) {
                return (
                    <div>
                        <h1>An Error Occurred</h1>
                        <p>{error.response.data.message}</p>
                    </div>
                )
            }
        }
        return (
            <div>An error occurred, please refresh the page or try again later.</div>
        )
    }
    if (status === "done") {

        const updateUser = async () => {

            let result = await api.post('/update-new-dealer-status', { dealer: user.dealer._id });
            await dispatch(setReduxUser({ dealer: result.data.dealer }));
            navigate("/order-confirm");
        }

        updateUser();
        return (
            <p>Redirecting...</p>
        )
    }

}

export default CreateOrder;