import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


import api from "../../api";

const ManageUserEmail = () => {
    let { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            try {
                let request = await api.post("/user", { id: id });
                setUser(request.data.user);
            } catch (err) {
                console.log(err);
            }

        }
        if (user === null) {
            getUser();
        }
    })

    const resetEmailValidation = Yup.object().shape({
        email: Yup.string().email('Invalid Email').required("Email Address is Required."),
        email_confirm: Yup.string().email('Invalid Email').oneOf([Yup.ref('email'), null], 'Email must match').required('Confirm Email is required.'),
    })


    if (user === null) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        )
    }

    if (user === "done") {
        return (
            <div className="confirmation">
            <h1>Edit Email Confirmation</h1>
            <p>Thank you! The email address has been changed.</p>
            <Link to="/admin" className="button button-primary">Return to User Management</Link>
            </div>
        )
    };

    return (
        <div className="manage-user">
            <h1>Edit Email Address</h1>
            <p>Enter a new email address for user #{user.dealer_number}.</p>
            <Formik
                initialValues={{ email: "", email_confirm: "" }}
                validationSchema={resetEmailValidation}
                onSubmit={async (values, { setSubmitting }) => {
                    let result = await api.post("updateUser", { id: user._id, changes: { email: values.email} });
                    console.log(result);
                    if (result.status === 200) {
                        setUser("done");
                    }
                    setSubmitting(false);
                    //TODO Send user email at changed email notifying them.
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="field-container">
                            <label htmlFor="email">Email</label>
                            <ErrorMessage name="email" component="div" />
                            <Field type="email" name="email" id="email" />
                           
                        </div>
                        <div className="field-container">
                            <label htmlFor="email_confirm">Confirm Email</label>
                            <ErrorMessage name="email_confirm" component="div" />
                            <Field type="email" name="email_confirm" id="email_confirm" />
                            
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ManageUserEmail;