import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import api from "../../api";

const ManageUserDelete = () => {
    let { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            try {
                let request = await api.post("/user", { id: id });
                setUser(request.data.user);
            } catch (err) {
                console.log(err);
            }

        }
        if (user === null) {
            getUser();
        }
    })

    if (user === null) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        )
    }

    if (user.status === "done") {
        return (
            <div className="manage-user">
            <h1>Delete User Confirmation</h1>
            <p>User { user.role === "dealer" ? user.dealer.name_company : <>{user.name_first} {user.name_last} </>} has been permanently deleted.</p>
            <Link to="/admin" className="button button-primary">Return to User Management</Link>
            </div>
        )
    };

    return (
        <div className="manage-user">
            <h1>Delete User</h1>
            <p>Are you sure you want to delete user { user.role === "dealer" ? user.dealer.name_company : <>{user.name_first} {user.name_last} </>}? This action cannot be undone.</p>
            <button className="button button-primary" onClick={ async () => {
                try {
                    let result = await api.post("deleteUser", { id: user._id });
                    if (result.status === 200) {
                        setUser({...user, status: "done"});
                    }

                } catch (err) {
                    console.log(err);
                }
                
            }}>
                Delete User
            </button>
        </div>
    )
}

export default ManageUserDelete;