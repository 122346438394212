import { createSlice, current } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: {
           role: null,
           email: null,
           name_first: null,
           name_last: null,
           dealer: {},
           dealer_number: null,
           dealer_selector: null,
           rep_number: null,
           status: null
        }

    },
    reducers: {
        setReduxUser: (state, action) => {
            console.log('Start state:', current(state));
            state.data = { ...state.data, ...action.payload };
            console.log('End state:', current(state));
        }
    }
})

// Action creators are generated for each case reducer function
export const { setReduxUser } = userSlice.actions

export default userSlice.reducer