import "normalize-css";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./redux/store";

import { Toaster } from 'react-hot-toast';

import AppContext from "./context/AppContext";

import "./App.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ForwardRef from "./components/ForwardRef";

import ErrorPage from './components/ErrorPage';

import ProtectedRoute from "./routes/ProtectedRoute";

import Home from "./routes/Home";
import StockCheck from './routes/StockCheck';
import Login from './routes/Login';
import Admin from './routes/Admin';
import ResetPassword from './routes/ResetPassword';
import SavedOrders from "./routes/SavedOrders";
import OpenOrders from "./routes/OpenOrders";
import InvoiceHistory from "./routes/InvoiceHistory";
import MainContent from "./components/MainContent";
import CreateUser from "./routes/CreateUser";
import ManageUser from "./routes/ManageUser";
import ManageUserEmail from "./routes/ManageUser/ManageUserEmail";
import ManageUserEdit from "./routes/ManageUser/ManageUserEdit";
import ManageUserPassword from "./routes/ManageUser/ManageUserPassword";
import ManageUserDisable from "./routes/ManageUser/ManageUserDisable";
import ManageUserDelete from "./routes/ManageUser/ManageUserDelete";
import AlternateRoute from "./routes/AlternateRoute";
import StockOrder from "./routes/StockOrder";
import MiniCart from "./components/MiniCart";
import Cart from "./routes/Cart";
import SaveCart from "./routes/SaveCart";
import RestoreCart from "./routes/RestoreCart";
import DeleteSavedOrder from "./routes/DeleteSavedOrder";
import OrderSaved from "./routes/OrderSaved";
import Checkout from "./routes/Checkout";
import OrderSummary from "./routes/OrderSummary";
import DealerConfirm from "./routes/DealerConfirm";
import OrderConfirmation from "./routes/OrderConfirmation";
import OpenOrder from "./routes/OpenOrder";
import LightSpeed from "./routes/LightSpeed";
import CreateOrder from "./routes/CreateOrder";
import ChangePassword from "./routes/ChangePassword";
import ForgotPassword from "./routes/ForgotPassword";
import ChangeEmail from "./routes/ChangeEmail";
import DealerList from "./routes/DealerList";
import ScrollToTop from "./components/ScrollToTop";
import InvoiceHistoryDetail from "./routes/InvoiceHistoryDetail";
import Finance from "./routes/Finance";
import EmailAdmin from "./routes/EmailAdmin";
import DeleteUnconfirmedOrder from "./routes/DeleteUnconfirmedOrder";

const App = () => {

  const [token, setToken] = useState(null);
  const [claims, setClaims] = useState({});
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [headerHeight, setHeaderHeight] = useState("0px");
  const [scrunched, setScrunched] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [showMiniCart, setShowMiniCart] = useState(window.location.pathname !== '/cart')



  const headerRef = useRef(null);
  const mainRef = useRef(null);

  const onScroll = useCallback(() => {
    const pixels = window.scrollY;
    let headerPart = headerRef.current.children[0].children[0].clientHeight;

    if (!scrunched) {
      if (pixels > 50) {
        console.log("Scrunching...");
        headerRef.current.style.marginTop = `-${headerPart}px`
        setScrunched(true);
        return;
      }
    } else {
      if (pixels === 0) {
        console.log("Unscrunching...");
        headerRef.current.style.marginTop = "0";
        setScrunched(false);
        return;
      }
    }


  }, [scrunched, headerRef]);

  useEffect(() => {
    if (isLoggedIn) {
      if (new Date(claims.exp) < new Date()) {
        setLoggedIn(false);
        console.log("Token Expired");
        let expiredDate = new Date().setDate(new Date().getDate() - 1);
        document.cookie = `jwt=; expires=${new Date(expiredDate).toUTCString()}; path='/';`;
        localStorage.removeItem("user");
      }
    }
  })

  useEffect(() => {
    if (claims.exp && claims.iat && claims.role) {
      if (new Date(claims.exp) > new Date()) {
        setLoggedIn(true);
      }
    }
  }, [claims])

  useEffect(() => {
    const jwt = document.cookie.split('; ').find(row => row.startsWith('jwt='));

    if (jwt) {
      let jwtToken = jwt.split('=')[1];
      if (jwt && !token) {
        setToken(jwtToken);
      }
    }

    if (token) {
      let claims = token.split(".")[1];
      console.log();
      let decodedClaims = JSON.parse(atob(claims));
      decodedClaims.exp = new Date(decodedClaims.exp * 1000);
      decodedClaims.iat = new Date(decodedClaims.iat * 1000);

      setClaims(decodedClaims);
    }
  }, [token]);

  useEffect(() => {
    const adjustHeight = () => {
      if (headerRef.current !== null) {
        setHeaderHeight(headerRef.current.clientHeight + 60);
        window.header = headerRef;
      } else {
        setTimeout(adjustHeight, 100); // Retry after 1 second
      }
    };
  
    adjustHeight();
  }, [headerRef]);

  useEffect(() => {
    setShowMiniCart(window.location.pathname !== '/cart');
  })

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [onScroll]);

  const onLogout = (force = false) => {
    let expiredDate = new Date().setDate(new Date().getDate() - 1);
    console.log(expiredDate);
    console.log("Logging Out...");
    const jwt = document.cookie.split('; ').find(row => row.startsWith('jwt='));

    if (jwt || force === true) {
      document.cookie = `jwt=; expires=${new Date(expiredDate).toUTCString()}; path='/';`;
      localStorage.removeItem('user');
      persistor.purge();
      window.location.reload()
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorPage />,
      element: (
        <div className="App">
          <Toaster containerStyle={{ zIndex: 999999 }} position="top-right" />
          <ScrollToTop />
          <AppContext.Provider value={{ token, claims, isLoggedIn, user, setToken, onLogout, setLoggedIn, setClaims, setUser, setShowMiniCart }}>
            <ForwardRef className="header-container" ref={headerRef} component={<Header />} />
            <MainContent headerHeight={headerHeight} mainRef={mainRef} >
              <Outlet context={[token, setToken]} />
            </MainContent>
            {(isLoggedIn && showMiniCart) && (
              <MiniCart />
            )}
          </AppContext.Provider>


          <Footer />

        </div>
      ),
      children: [
        {
          path: "/stock",
          element: <AlternateRoute component={StockOrder} alternateComponent={StockCheck} />,
        },
        {
          path: "/",
          element: <ProtectedRoute component={Home} />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/admin",
          element: <ProtectedRoute component={Admin} />,
        },
        {
          path: "/saved-orders",
          element: <ProtectedRoute component={SavedOrders} />,
        },
        {
          path: "/saved-orders/:id",
          element: <ProtectedRoute component={RestoreCart} />,
        },
        {
          path: "/saved-orders/:id/delete",
          element: <ProtectedRoute component={DeleteSavedOrder} />,
        },
        {
          path: "/unconfirmed-orders/:id/delete",
          element: <ProtectedRoute component={DeleteUnconfirmedOrder} />,
        },
        {
          path: "/order-saved",
          element: <ProtectedRoute component={OrderSaved} />,
        },
        {
          path: "/open-orders",
          element: <ProtectedRoute component={OpenOrders} />,
        },
        {
          path: "/open-order/:type/:id",
          element: <ProtectedRoute component={OpenOrder} />,
        },
        {
          path: "/invoice-history",
          element: <ProtectedRoute component={InvoiceHistory} />,
        },
        {
          path: "/invoice-history/:id",
          element: <ProtectedRoute component={InvoiceHistoryDetail} />,
        },
        {
          path: "/resetPassword/:token",
          element: <ResetPassword />,
        },
        {
          path: "/change-password",
          element: <ProtectedRoute component={ChangePassword} />
        },
        {
          path: "/change-email",
          element: <ProtectedRoute component={ChangeEmail} />
        },
        {
          path: "/createUser", element: <ProtectedRoute component={CreateUser} />,
        },
        {
          path: "/manageUser/:type", element: <ProtectedRoute component={ManageUser} />
        },
        {
          path: "/manageUser/email/:id", element: <ProtectedRoute component={ManageUserEmail} />
        },
        {
          path: "/manageUser/edit/:id", element: <ProtectedRoute component={ManageUserEdit} />
        },
        {
          path: "/manageUser/password/:id", element: <ProtectedRoute component={ManageUserPassword} />
        },
        {
          path: "/manageUser/disable/:id", element: <ProtectedRoute component={ManageUserDisable} />
        },
        {
          path: "/manageUser/delete/:id", element: <ProtectedRoute component={ManageUserDelete} />
        },
        {
          path: "/cart",
          element: <ProtectedRoute component={Cart} />
        },
        {
          path: "/checkout",
          element: <ProtectedRoute component={Checkout} />
        },
        {
          path: "/checkout/:data",
          element: <ProtectedRoute component={Checkout} />
        },
        {
          path: "/order-summary/:data",
          element: <ProtectedRoute component={OrderSummary} />
        },
        {
          path: "/dealer-order-confirm/:data",
          element: <ProtectedRoute component={DealerConfirm} />
        },
        {
          path: "/finalize/:data",
          element: <ProtectedRoute component={CreateOrder} />
        },
        {
          path: "/order-confirm",
          element: <ProtectedRoute component={OrderConfirmation} />
        },
        {
          path: "/save-cart",
          element: <ProtectedRoute component={SaveCart} />
        },
        {
          path: "/lightspeed",
          element: <ProtectedRoute component={LightSpeed} />
        },
        {
          path: "/dealer-list",
          element: <ProtectedRoute component={DealerList} />
        },
        {
          path: "/dealer-list/:id",
          element: <ProtectedRoute component={DealerList} />
        },
        {
          path: "/finances",
          element: <ProtectedRoute component={Finance} />
        },
        {
          path: "/email-admin",
          element: <ProtectedRoute component={EmailAdmin} />
        }
      ]
    },
  ]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
}

export default App;
