import { useEffect, useState, useRef } from "react";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import dayjs from 'dayjs';

const SavedOrders = () => {

    const [orders, setOrders] = useState(null);
    const prevOrdersRef = useRef();
    const user = useSelector(state => state.user.data);



    const navigate = useNavigate();

    useEffect(() => {
        prevOrdersRef.current = orders;
    }, [orders]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await api.post('/saved-orders', { user: user.dealer.customer_number });
                console.log(result);
                if (prevOrdersRef.current !== result.data.orders) {
                    setOrders(result.data.orders);
                }
            } catch (err) {
                console.warn(err.response.data.message);
                setOrders([]);
            }
        };
        fetchData();
        console.log(orders);
    }, [prevOrdersRef]);

    const renderSavedOrders = () => {

        console.log(orders);

        if (orders === null) {
            return <p>Loading...</p>
        };

        if (user.role !== 'dealer') {
            if (user.dealer_number === null ) {
                return <p>No dealer selected. Please select a dealer to continue...</p>
            }
        }

        if (orders.length === 0) {
            return <p>There's nothing here. Please save an order.</p>
        }

        return orders.map((order, index) => {
            return (
                <div key={index} className="result__container">
                    <div className="name result">
                        <p class="title">Order Name:</p>
                        <p className="name__data result__data"><Link to={`/saved-orders/${order._id}`}>{order.order_name}</Link></p>
                    </div>
                    <div className="timestamp result">
                        <p class="title">Order Last Edited:</p>
                        <p className="timestamp__data result__data">{dayjs(order.updatedAt).format('MM/DD/YY')}</p>
                    </div>
                    <div className="number result">
                        <p class="title"># of Parts in Order:</p>
                        <p className="number__data result__data">{order.item_count}</p>
                    </div>
                    <div className="item-remove">
                        <FontAwesomeIcon icon={solid("trash-xmark")} color="#A91108" onClick={() => { navigate(`/saved-orders/${order._id}/delete`) }} />
                    </div>
                </div>
            )
        }

        )
    }

    return (
        <div >
            <h1>Saved Orders</h1>
            <p>If you have any saved orders that haven't been submitted yet, they will appear here. You may only have 5 saved orders at a time. To delete a saved order, click the trash icon.</p>
            <div className="saved-orders-container">
                {renderSavedOrders()}
            </div>
        </div>
    )
}

export default SavedOrders;