import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


import api from "../../api";
import { ROLES, getLabelFromRole, getRoleObject } from "../../constants/roles";

import { ReactSelect } from "../../components/Formik/ReactSelect";
import { SELECT_STYLES_ALT } from "../../constants/styles";
import FormErrorNotification from "../../components/Formik/FormErrorNotification";

const ManageUserEdit = () => {
    let { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            try {
                let request = await api.post("/user", { id: id });
                setUser(request.data.user);
            } catch (err) {
                console.log(err);
            }

        }
        if (user === null) {
            getUser();
        }
    })

    const resetEmailValidation = Yup.object().shape({
        name_first: Yup.string().required("First Name is Required"),
        name_last: Yup.string().required("Last Name is Required"),
        email: Yup.string().email('Invalid Email').required("Email Address is Required."),
        
    })


    if (user === null) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        )
    }

    if (user.status === "done") {
        return (
            <div className="manage-user">
                <h1>Confirmation</h1>
                <p>Your changes for {user.name_first} {user.name_last} have been made.</p>
                <Link to="/admin" className="button button-primary">Return to User Management</Link>
            </div>
        )
    };

    const roleOptions = [
        { label: "Select", value: "select" },
        ROLES.admin,
        ROLES.superUser
    ];

    return (
        <div className="manage-user">
            <h1 style={{ textTransform: "capitalize" }}>Edit {getLabelFromRole(user.role)}</h1>
            <Formik
                initialValues={{ rep_number: user.rep_number, name_first: user.name_first, name_last: user.name_last, email: user.email, role: user.role }}
                validationSchema={resetEmailValidation}
                onSubmit={async (values, { setSubmitting }) => {
                    let result = await api.post("updateUser", { id: user._id, changes: { ...values } });
                    console.log(result);
                    if (result.status === 200) {
                        setUser({...result.data.user, status: "done"});
                    }
                    setSubmitting(false);
                    //TODO Send user email at changed email notifying them.
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FormErrorNotification />
                        {user.role === 'sales' && (
                            <div className="field-container">
                                <label htmlFor="rep_number">Sales Rep #</label>
                                <ErrorMessage name="rep_number" component="div" />
                                <Field name="rep_number" id="rep_number" />
                            </div>
                        )}
                        <div className="field-container">
                            <label htmlFor="name_first">First Name</label>
                            <ErrorMessage name="name_first" component="div" />
                            <Field name="name_first" id="name_first" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="name_last">Last Name</label>
                            <ErrorMessage name="name_last" component="div" />
                            <Field name="name_last" id="name_last" />

                        </div>
                        <div className="field-container">
                            <label htmlFor="email">Email</label>
                            <Field type="email" name="email" id="email" />
                            <ErrorMessage name="email" component="div" />
                        </div>
                        {(user.role === 'admin' || user.role === 'superUser') && (
                            <div className="field-container">
                                <label htmlFor="role">User Role</label>
                                <ErrorMessage name="role" component="div" />
                                <Field
                                    component={ReactSelect}
                                    id="role"
                                    name="role"
                                    styles={SELECT_STYLES_ALT}
                                    options={roleOptions}
                                />

                            </div>
                        )}
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ManageUserEdit;