import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import api from "../api";
import AppContext from '../context/AppContext';
import NotificationToast from "../components/NotificationToast";

import { useDispatch } from "react-redux";
import { setReduxUser } from '../redux/userSlice';


const Login = () => {

    const loginValidation = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
    })

    const context = useContext(AppContext);
    const { token, setToken, isLoggedIn, setUser } = context;

    const dispatch = useDispatch();

    console.log(token);

    if (isLoggedIn === true && context.claims) {
        console.log("SHOULD REDIRECT");
        console.log(isLoggedIn);
        return <Navigate to="/" replace />
    }

    return (

        <div className='login-container'>
            <h1> Login </h1>
            <div className='login-form'>
                <Formik
                    initialValues={{ username: "", password: "" }}
                    validationSchema={loginValidation}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            let userResult = await api.post("login", { username: values.username, password: values.password });
                            localStorage.setItem("user", JSON.stringify(userResult.data.user));
                            setToken(userResult.data.token);
                            setUser(userResult.data.user);
                            await dispatch(setReduxUser(userResult.data.user));
                            try {
                                let result = await api.post('/update-new-dealer-status', { dealer: user.dealer._id });
                                await dispatch(setReduxUser({ dealer: result.data.dealer }));
                                await api.post("update-new-dealer-status")
                            } catch (err) {
                                console.log("failed to update new dealer status");
                            }
                            

                        } catch (err) {
                            console.log(err);
                            toast.custom(
                                (t) => (
                                    <NotificationToast header={'Login Failed'} body={"Username or Password Incorrect."} type="danger" toastObject={t} />
                                ), { duration: 4000, id: "login-failed"}
                            );
                        }


                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <>
                        <Form>
                            <div className="field-container">
                                <label htmlFor="username">Account # or Email Address</label>
                                <ErrorMessage name="username" component="div" className='error'/>
                                <Field type="text" name="username" autoComplete="username" />
                                
                            </div>
                            <div className="field-container">
                                <label htmlFor="password">Password</label>
                                <ErrorMessage name="password" component="div" className='error' />
                                <Field type="password" name="password" autoComplete="current-password"/>
                                
                            </div>
                            <button type="submit" disabled={isSubmitting}>
                                Login
                            </button>
                            
                        </Form>
                        <Link to="/forgot-password" >Forgot Password </Link>
                        </>
                    )}
                </Formik>
            </div>

        </div>
    )
}

export default Login;
