import { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import toast from 'react-hot-toast';

import { ReactSelect, ReactAsyncSelect } from "../components/Formik/ReactSelect";

import api from "../api";
import AppContext from "../context/AppContext";
import { SELECT_STYLES_ALT } from "../constants/styles";
import { ROLES, ROLES_ARRAY } from "../constants/roles";
import NotificationToast from "../components/NotificationToast";
import FormErrorNotification from "../components/Formik/FormErrorNotification";
import { Link } from "react-router-dom";


const CreateUser = () => {

    let context = useContext(AppContext);
    const [status, setStatus] = useState('initial');

    const createUserValidation = Yup.object().shape({
        email: Yup.string().email('Invalid Email').required("Email Address is Required."),
        email_confirm: Yup.string().email('Invalid Email').oneOf([Yup.ref('email'), null], 'Email must match').required('Confirm Email is required.'),
        role: Yup.string().required("User Role is required."),
        dealer: Yup.object().test(
            'dealer-validation',
            'Please select a dealer for this account.',
            function (value) {
                const { role } = this.parent;
                return role !== 'dealer' || (role === 'dealer' && !!value.id);
            }
        ).shape({ value: Yup.string().notOneOf(["select"]) }),
        sales_rep: Yup.string().test(
            'sales-rep-validation',
            'Please enter the Sales Rep # for this account.',
            function (value) {
                const { role } = this.parent;
                return role !== 'sales' || (role === 'sales' && !!value);
            }
        ),
        name_first: Yup.string().test(
            'first-name-validation',
            'First Name is required.',
            function (value) {
                const { role } = this.parent;
                const allowedRoles = ['sales', 'admin', 'superUser'];
                return role === 'dealer' || allowedRoles.includes(role) && !!value;
            }
        ),
        name_last: Yup.string().test(
            'last-name-validation',
            'Last Name is required.',
            function (value) {
                const { role } = this.parent;
                const allowedRoles = ['sales', 'admin', 'superUser'];
                return role === 'dealer' || allowedRoles.includes(role) && !!value;
            }
        )

    });

    const roleOptions = [
        { label: "Select", value: "select" },
        ROLES.dealer
    ];

    if (context.claims.role === "superUser") {
        roleOptions.push(
            ROLES.sales, ROLES.admin, ROLES.superUser
        )
    }

    const getDealerSelectorOptions = async () => {
        try {
            let dealers = await api.post('/dealers', { role: context.claims.role });


            dealers = dealers.data.dealers;

            let options = dealers.map(dealer => {
                return ({
                    value: { id: dealer._id, number: dealer.customer_number },
                    label: `${dealer.customer_number} (${dealer.name_company})`
                });

            })
            return Promise.resolve(options);


        } catch (err) {
            console.log(err);
        }

    }

    const renderForm = (values, errors, isSubmitting) => {

        switch (values.role) {

            case "dealer": {
                return (
                    <>
                        <div className="field-container">
                            <label htmlFor="dealer">Account #</label>
                            {(errors.dealer && values.email_confirm) && (
                                <div>{errors.dealer}</div>
                            )}
                            <ErrorMessage name="dealer" component="div" className='error' />
                            <Field
                                component={ReactAsyncSelect}
                                id="dealer"
                                name="dealer"
                                styles={SELECT_STYLES_ALT}
                                asyncOptions={{ loadOptions: getDealerSelectorOptions, cache: true }}
                            />

                        </div>
                        <div className="field-container">
                            <label htmlFor="email">Email</label>
                            <ErrorMessage name="email" component="div" className='error' />
                            <Field type="email" name="email" id="email" />

                        </div>
                        <div className="field-container">
                            <label htmlFor="email_confirm">Confirm Email</label>
                            <ErrorMessage name="email_confirm" component="div" className='error' />
                            <Field type="email" name="email_confirm" id="email_confirm" />

                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </>
                )
            }
            case "sales": {
                return (
                    <>
                        <div className="field-container">
                            <label htmlFor="sales_rep">Sales Rep #</label>
                            {(errors.sales_rep && values.email_confirm) && (
                                <div>{errors.dealer}</div>
                            )}

                            <ErrorMessage name="sales_rep" component="div" className='error' />
                            <Field type="text" name="sales_rep" id="sales_rep" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="name_first">First Name</label>
                            <ErrorMessage name='name_first' component="div" className='error' />
                            <Field type="text" name="name_first" id="name_first" />

                        </div>
                        <div className="field-container">
                            <label htmlFor="name_last">Last Name</label>
                            <ErrorMessage name='name_last' component="div" className='error' />
                            <Field type="text" name="name_last" id="name_last" />

                        </div>


                        <div className="field-container">
                            <label htmlFor="email">Email</label>
                            <ErrorMessage name="email" component="div" className='error' />
                            <Field type="email" name="email" id="email" />

                        </div>
                        <div className="field-container">
                            <label htmlFor="email_confirm">Confirm Email</label>
                            <ErrorMessage name="email_confirm" component="div" className='error' />
                            <Field type="email" name="email_confirm" id="email_confirm" />

                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </>
                )
            }
            case "admin":
            case "superUser": {
                return (
                    <>
                        <div className="field-container">
                            <label htmlFor="name_first">First Name</label>
                            <ErrorMessage name='name_first' component="div" className='error' />
                            <Field type="text" name="name_first" id="name_first" />

                        </div>
                        <div className="field-container">
                            <label htmlFor="name_last">Last Name</label>
                            <ErrorMessage name='name_last' component="div" className='error' />
                            <Field type="text" name="name_last" id="name_last" />

                        </div>
                        <div className="field-container">
                            <label htmlFor="email">Email</label>
                            <ErrorMessage name="email" component="div" className='error' />
                            <Field type="email" name="email" id="email" />

                        </div>
                        <div className="field-container">
                            <label htmlFor="email_confirm">Confirm Email</label>
                            <ErrorMessage name="email_confirm" component="div" className='error' />
                            <Field type="email" name="email_confirm" id="email_confirm" />

                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </>
                )

            }

        }
    }


    if (status === 'done') {
        return (
            <div className="confirmation">
                <h1>New User Confirmation</h1>
                <p>This new user has been set up. The user has been notified.</p>
                <Link to="/admin" className="button button-primary">Return to User Management</Link>
            </div>
        )
    }


    return (
        <div className="create-user">
            <h1>Add New User</h1>

            <Formik
                initialValues={{ email: '', role: 'select' }}
                validationSchema={createUserValidation}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        //{ email, role, host, dealer, rep_number, name_first, name_last }
                        let result = await api.post("createUser", { email: values.email, role: values.role, dealer: values.dealer?.id, dealer_number: values.dealer?.number, rep_number: values.sales_rep, name_first: values.name_first, name_last: values.name_last })
                        console.log(result);
                        if (result.status === 200) {
                            setStatus("done");
                        }
                        setSubmitting(false);
                    } catch (err) {
                        console.log(err);
                        if (err.response.status === 409) {
                            console.log("Email Address already taken.");
                            toast.custom(
                                (t) => (
                                    <NotificationToast header={'User Creation Failed'} body={"Email Address already in use. Please choose a different email address."} type="danger" toastObject={t} />
                                ), { duration: 4000, id: "email-taken" }
                            );
                        }

                    }

                }}
            >

                {({ isSubmitting, values, errors }) => (

                    <Form>
                        <FormErrorNotification />
                        <div className="field-container">
                            <label htmlFor="role">User Role</label>
                            <ErrorMessage name="role" component="div" />
                            <Field
                                component={ReactSelect}
                                id="role"
                                name="role"
                                styles={SELECT_STYLES_ALT}
                                options={roleOptions}
                            />

                        </div>
                        {values.role !== "select" && (

                            renderForm(values, errors, isSubmitting)

                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateUser;