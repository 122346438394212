import { useContext, useState, useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";
import AppContext from "../context/AppContext"
import { useSelector } from "react-redux";
import { persistor } from "../redux/store";

const ProtectedRoute = (props) => {
    const Component = props.component;
    const context = useContext(AppContext);
    const { isLoggedIn } = context;
    const navigate = useNavigate();

    const user = useSelector(state => state.user.data);

    let loggedIn = isLoggedIn;

    const jwt = document.cookie.split('; ').find(row => row.startsWith('jwt='));
    let expiredDate = new Date().setDate(new Date().getDate() - 1);

    if (jwt === undefined) {
        loggedIn = false
    }

    if (user.role === null) {
        //loggedIn = false
        document.cookie = `jwt=; expires=${new Date(expiredDate).toUTCString()}; path='/';`;
        //localStorage.removeItem('user');
        //persistor.purge();
    }


    switch (loggedIn) {
        case true: {
            return <Component />
        }
        case false: {
            console.log("Unauthorized, please log in");
            context.setLoggedIn(false);
            context.setToken(null);
            context.setClaims({});
            return <Navigate to="/login" replace />
        }
        default: {
            return (
                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                    <p style={{ flexBasis: "100%" }}> Loading... </p>
                    <BarLoader />
                </div>
            )
        }
    }



}

export default ProtectedRoute;