export const ROLES = { 
    dealer: { value: "dealer", label: "Dealer"}, 
    sales: { value: "sales", label: "Sales Representative"}, 
    admin: { value: "admin", label: "SMS Admin"}, 
    superUser: { value: "superUser", label: "Super User"}
};

export const getLabelFromRole = (role) => {
    return ROLES[role].label
}

export const getRoleObject = (role) => {
    return ROLES[role];
}

export const ROLES_ARRAY = [ ROLES.dealer.value, ROLES.sales.value, ROLES.admin.value, ROLES.superUser.value ];