import { useSelector } from "react-redux";
import dayjs from "dayjs";


const Finance = () => {

    const user = useSelector(state => state.user.data);
    let dateString = user.dealer.date_last_payment;

    if (user.dealer_number === null) {
        return(
            <div>
                <h1>Finances</h1>
                <p>Please select a dealer to view the finances tab.</p>
            </div>
        )

    }

    return (
        <div>
            <h1>Finances</h1>
            <div className="total-container">
                <p className="title">Total Amount Due:</p>
                <p className="total">{user.dealer.amount_due.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}</p>
            </div>
            <p className="label">Amount Due By Period</p>
            <div className="amount-due-container">
                <div className="current-container"><span className="title">Current:</span><span className="value">{user.dealer.billing_period_amount.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}</span></div>
                <div className="period-container">
                    <div className={`period period-1 ${user.dealer.period_1_amount > 0 ? "red" : ""}`}>
                        <span className="title">Period 1:</span>
                        <span className="value">{user.dealer.period_1_amount.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }        )}</span>
                    </div>
                    <div className={`period period-2 ${user.dealer.period_2_amount > 0 ? "red" : ""}`}>
                        <span className="title">Period 2:</span>
                        <span className="value">{user.dealer.period_2_amount.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className={`period period-3 ${user.dealer.period_3_amount > 0 ? "red" : ""}`}>
                        <span className="title">Period 3:</span>
                        <span className="value">{user.dealer.period_3_amount.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className={`period period-4 ${user.dealer.period_4_amount > 0 ? "red" : ""}`}>
                        <span className="title">Period 4+:</span>
                        <span className="value">{user.dealer.period_4_amount.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
                    </div>
                <p className="legend">Red indicates amounts currently due.</p>
            </div>
            <p className="label">Account Information</p>
            <div className="account-info-container">
                <div className="billing-address-container">
                    <p>Billing Address:</p>
                    <p className="bold">{user.dealer.address.billing.name}</p>
                    <p className="mb-0">{user.dealer.address.billing.street}</p>
                    <p>{user.dealer.address.billing.city} {user.dealer.address.billing.state}, {user.dealer.address.billing.postal}</p>
                </div>
                <div className="last-paid-container">
                    <p clas>Last Paid:</p>
                    <p className="bold">{dayjs(`20${dateString.substring(1)}`, 'YYYYMMDD').format('M/D/YY')}</p>
                </div>
                <div className="terms-code-container">
                    <p>Terms Code:</p>
                    <p className="bold uppercase">{user.dealer.term_code}</p>
                </div>
                <div className="account-type-container">
                    <p>Account Type:</p>
                    <p className="bold uppercase">{user.dealer.statement_type_code}</p>
                </div>
                <div className={`account-status ${user.dealer.suspended ? "suspended" : ""}`}>
                    <p>Account Status:</p>
                    <p className={`bold`} >{user.dealer.suspended ? "SUSPENDED" : "ACTIVE"}</p>
                </div>
            </div>
        </div>
    )

}

export default Finance;