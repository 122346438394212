import { useEffect, useState } from "react";
import api from "../api";
import Select from "react-select";
import { LABEL_FILTER } from "../constants/filters";
import { SELECT_STYLES_ALT } from "../constants/styles";
import { toast } from "react-hot-toast";
import NotificationToast from "../components/NotificationToast";

const EmailAdmin = () => {

    const [files, setFiles] = useState(null);
    const [index, setIndex] = useState(undefined);
    const [checkboxStates, setCheckboxStates] = useState({});
    const [input, setInput] = useState("");

    const getData = async () => {
        try {
            let fileList = await api.get('/get-file-list');
            setFiles(fileList.data.files);
        }
        catch (err) {
            console.warn(err);
            setFiles([])
        }

    }

    useEffect(() => {
        getData();
    }, [])

    if (files === null) {
        return (<p>Loading....</p>);
    }

    const handleCheckboxChange = (fileId, isChecked) => {
        setCheckboxStates(prevStates => ({
            ...prevStates,
            [fileId]: isChecked
        }));
        if (isChecked === false) {

            saveRecipient(fileId, null);
            if (files[index].recipient !== null) {
                toast.custom(
                    (t) => (
                        <NotificationToast header={'Success'} body={"Your changes were saved."} type="success" toastObject={t} />
                    ), { duration: 4000, id: "character-limit" }
                );
                setInput("");
            }
        }
    };

    const saveRecipient = async (id, recipient) => {
        await api.post("update-file-recipient", { id, recipient });
        await getData();
    }

    const handleInputChange = (fileId, value) => {
        const emailRegex = /^(?:[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,},\s?)*(?:[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        if (emailRegex.test(value)) {
            if (value !== "") {
                saveRecipient(fileId, value);
                toast.custom(
                    (t) => (
                        <NotificationToast header={'Success'} body={"Your changes were saved."} type="success" toastObject={t} />
                    ), { duration: 4000, id: "success-save" }
                );
            }
        } else {
            toast.custom(
                (t) => (
                    <NotificationToast header={'Invalid Email'} body={"The provided input does not appear to be correct. Emails must be valid, and multiple emails must be separated by commas and a single space."} type="danger" toastObject={t} />
                ), { duration: 4000, id: "invalid-email-format" }
            );
        }
    };

    const renderFiles = () => {
        if (index === undefined) {
            return;
        }

        let file = files[index]

        return (
            <>


                <div key={files[index]._id} className="file">
                    <form>
                        <label className="setting">
                            <span className="setting__label">Email File?</span>
                            <span className="switch">
                                <input className="switch__input" type="checkbox" role="switch" name="switch2" onChange={(e) => handleCheckboxChange(files[index]._id, e.target.checked)}
                                    checked={checkboxStates[files[index]._id] || files[index].recipient !== null} />
                                <span className="switch__fill" aria-hidden="true">
                                    <span className="switch__text">ON</span>
                                    <span className="switch__text">OFF</span>
                                </span>
                            </span>
                        </label>
                    </form>
                    {(checkboxStates[files[index]._id] || files[index].recipient !== null) && (
                        <>
                            <p className="mb-0" style={{ flex: "1 1 100%" }} >Enter recipient email addresses below.</p>
                            <p className="mt-0" style={{ flex: "1 1 100%" }}>Separate multiple recipients using commas</p>
                            <div className="recipient-container">

                                <input
                                    className="recipient-input"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                />
                                <button className="recipient-button" onClick={(e) => handleInputChange(files[index]._id, input)}>Submit</button>
                            </div>
                        </>
                    )}

                </div>
            </>
        )
    }

    const selectOptions = files.map((file) => {
        return ({
            value: file,
            label: `${file.name} ${file.recipient ? `| ${file.recipient}` : ''}`
        })
    });


    return (
        <div className="email-admin">
            <h1>Manage Email File Delivery</h1>
            <p style={{ maxWidth: "30rem", marginLeft: "auto", marginRight: "auto" }}>Select a file below to manage email delivery</p>
            <p style={{ maxWidth: "30rem", marginLeft: "auto", marginRight: "auto" }}>Files will be mailed to selected recipients whenever the file on the server changes (at maximum once every 5 minutes). Be mindful of the upload frequency to avoid spamming the recipient.</p>
            <Select
                key={files}
                className="file-selector"
                filterOption={LABEL_FILTER}
                styles={SELECT_STYLES_ALT}
                onChange={async (e) => {
                    console.log(e);
                    let index = files.findIndex(file => file._id === e.value._id);
                    if (index !== -1) {
                        setIndex(index);
                        if (files[index].recipient === null) {
                            setInput("");
                        } else {
                            setInput(files[index].recipient);
                        }
                    } else {
                        setIndex(undefined);
                    }
                }}
                options={selectOptions}
            />

            <div className="file-list">

                {renderFiles()}
            </div>
        </div>
    )
}

export default EmailAdmin;