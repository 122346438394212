import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../api";
import LinkWrapper from "../components/LinkWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch } from "react-redux";
import { replaceCart } from "../redux/cartSlice";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

const RestoreCart = () => {

    const { id } = useParams();
    const [order, setOrder] = useState(null);

    const { width } = useWindowDimensions();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            let result = await api.post("/saved-order", { id });
            console.log(result);
            setOrder(result.data.order);
        }

        fetchData();

    }, [])

    if (order === null) {
        return (
            <p>Loading...</p>
        )
    }


    const renderOrder = () => {
        if (order === null) {
            return (
                <p>Loading...</p>
            )
        }
        if (width < 768) {
            return order.cart.map((product, index) => {
                return (
                    <div key={product.id} className="result__container">
                        <div className="part-number result">
                            <p className="title">Part Number</p>
                            <p className="part-number__data result__data">{product.item.item_number}</p>
                        </div>

                        <div className="part-description result">
                            <p className="title">Part Description</p>
                            <p className="part-description__data result__data">{product.item.item_description}</p>
                            {product.item.manufacturer_number !== "" && (
                                <p className="part-manufacturer__data result__data">MFG#: {product.item.manufacturer_number}</p>
                            )}

                        </div>

                        <div className="quantity result">
                            <p className="title">Quantity</p>
                            <p className="quantity__data result__data">{product.quantity}</p>
                        </div>

                        <div className="price result">
                            <p className="title">Suggested Retail Price</p>
                            <p className="price__data result__data">${product.item.retail_price.toFixed(2)}</p>
                        </div>

                        <div className="dealer-price result">
                            <p className="title"> Dealer Price</p>
                            <p className="dealer-price__data result__data">${product.item.dealer_price?.toFixed(2) ?? product.item.retail_price.toFixed(2)}</p>
                        </div>


                    </div>
                )
            });

        }
        return order.cart.map((product, index) => {
            return (
                <div key={index} className="result__container">
                    <div className="part-number result">
                        <p className="part-number__data result__data">{product.item.item_number}</p>
                    </div>

                    <div className="part-description result">
                        <p className="part-description__data result__data">{product.item.item_description}</p>
                        {product.item.manufacturer_number !== "" && (
                            <p className="part-manufacturer__data result__data">MFG#: {product.item.manufacturer_number}</p>
                        )}
                    </div>
                    <div className="quantity result">
                        <p className="quantity__data result__data">{product.quantity}</p>
                    </div>
                    <div className="price result">
                        <p className="price__data result__data">${product.item.retail_price.toFixed(2)}</p>
                    </div>
                    <div className="dealer-price result">
                        <p className="dealer-price__data result__data">${product.item.dealer_price?.toFixed(2) ?? product.item.retail_price.toFixed(2)}</p>
                    </div>
                </div>
            )
        }
        )
    }

    return (
        <div className="restore-saved-order">
            <h1> Order "{order.order_name}"</h1>
            <p>Do you want to add the items below into your cart? Any items already in your cart will be deleted.</p>
            <button className="button button-primary" onClick={() => {
                dispatch(replaceCart(order.cart));
                navigate('/cart');
            }}>Add To Cart</button>
            <div className="saved-orders-container">
                {width >= 768 && (
                    <div className="saved-orders-header">
                        <p className="part-number">Part Number</p>
                        <p className="part-description">Part Description</p>
                        <p className="quantity">Quantity</p>
                        <p className="price">Suggested Retail Price</p>
                        <p className="dealer-price">Dealer Price</p>
                    </div>
                )}
                {renderOrder()}
            </div>

            <LinkWrapper link={`/saved-orders/${order._id}/delete`} label="Delete Saved Order" containerClass="delete-control">
                <FontAwesomeIcon icon={solid("trash-xmark")} />
            </LinkWrapper>


        </div>
    )

}

export default RestoreCart;