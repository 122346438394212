import { useSelector } from "react-redux";


const CartQuantity = () => {
    const cart = useSelector(state => state.cart.products);

    let totalQuantity = cart.reduce( (accumulator, currentProduct) => {
        return accumulator + currentProduct.quantity;
    }, 0);



    let digits = totalQuantity.toString().length;

    if (digits > 3) {
        digits = 3; 
    }

    if (totalQuantity > 99) {
        totalQuantity = "99+"
    }


    return (<span className={`count digit__${digits}`}>{totalQuantity}</span>);

}

export default CartQuantity;