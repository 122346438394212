import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import useSound from 'use-sound';

import ErrorSound from "../assets/sounds/error.mp3";
import SuccessSound from "../assets/sounds/success.mp3";


const NotificationToast = ({ header, body, type, toastObject }) => {

    const [ playError ] = useSound(ErrorSound, { interrupt: true });
    const [ playSuccess ] = useSound(SuccessSound, { interrupt: true });

    const toastRef = useRef(null);

    useEffect(() => {
        if (toastObject) {
            if (toastRef.current) {
                if (toastObject.visible === true) {
                    setTimeout(() => {
                        toastRef.current.classList.add("animate-in");
                        
                    }, 100);
                }
            }
        }
    })

    useEffect(() => {
        if (toastObject) {
            if (toastObject.visible) {
                switch (type) {
                    case "danger": {
                        playError();
                        break;
                    }
                    case "success": {
                        playSuccess();
                        break;
                    }
                }
            }
        }
    })


    return (
        <div className={`toast ${type}`} role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef}>
            <div className="toast-header bg-danger">
                <strong className="me-auto">{header}</strong>
                <button onClick={() => {
                    toastRef.current.classList.remove("animate-in");
                    toast.dismiss(toastObject.id);
                }}>
                    <FontAwesomeIcon icon={solid("xmark")} size="2x" />
                </button>
            </div>
            <div className="toast-body bg-danger">
                {body}
            </div>
        </div>
    )
}


export default NotificationToast;