import { useEffect, useState } from "react";
import api from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LinkWrapper from "../components/LinkWrapper";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const OpenOrder = () => {

    const [order, setOrder] = useState(null);
    const { type, id } = useParams();
    const user = useSelector(state => state.user.data);
    const { width } = useWindowDimensions();

    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const getData = async () => {
            let result;
            switch (type) {
                case "confirmed": {
                    result = await api.post("/open-order", { id });
                    break;
                };
                case "unconfirmed": {
                    result = await api.post("/unconfirmed-order", { id });
                    break;
                }
                default: {
                    throw new Error("Invalid Path");
                }
            }

            console.log("Fetching Item Data (for quantity)");
            let itemNumbers = result.data.order.parts.filter(obj => obj.item_number !== "").map(obj => obj.item_number);
            console.log(itemNumbers);
            let itemDetails = await api.post("/price-by-item-numbers", { items: itemNumbers });
            console.log(itemDetails);
            if (itemDetails.data.items[0] !== null) {
                result.data.order.parts.forEach(item => {
                    let match = itemDetails.data.items.find(detailItem => detailItem?.item_number === item?.item_number);
                    if (match) {
                        item.quantity_available = match.quantity;
                        if (item.retail_price === undefined) {
                            item.retail_price = match.retail_price
                            item.dealer_price = match.retail_prices
                        }
                    }
                })
            }



            console.log(itemDetails);
            setOrder(result.data.order);
        }
        getData();
    }, [])

    if (order === null) {
        return (
            <div>Loading...</div>
        )
    }

    if (order !== null && parseInt(order.customer_number) !== parseInt(user.dealer_number)) {
        navigate("/open-orders");
    }

    const renderOrder = () => {

        if (width < 768) {
            return order.parts.map((part) => {
                return (
                    <div className="part">
                        <div className="part-number result">
                            <p className="title">Part Number</p>
                            <p className="part-number__data result__data">{part.item_number}</p>
                        </div>
                        <div className="part-description result">
                            <p className="title">Part Description</p>
                            <p className="part-description__data result__data">{part.item_description}</p>
                            {part.manufacturer_number && (
                                <p className="part-manufacturer__data result__data">MFG#: {part.manufacturer_number}</p>
                            )}
                        </div>
                        <div className="price result">
                            <p className="title">Suggested Retail Price</p>
                            <p className="price__data result__data">{part.retail_price.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>

                        </div>
                        <div className="dealer-price result">
                            <p className="title">Dealer Price</p>
                            <p className="dealer-price__data result__data">{part.dealer_price.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                        <div className="quantity result">
                            <p className="title">Qty Ordered</p>
                            <p className="quantity__data result__data">{part.quantity}</p>
                        </div>
                        {order.pending_shipment && (
                        <>
                            <div className="quantity_shipped result">
                                <p className="title">Qty Shipped</p>
                                <p className="quantity_shipped__data result__data">{part.quantity_shipped}</p>
                            </div>
                            <div className="quantity_backordered result">
                                <p className="title">Qty Backordered</p>
                                <p className="quantity_backordered__data result__data">{part.quantity_backordered}</p>
                            </div>
                        </>
                    )}
                        {!order.pending_shipment && (
                            <>
                                {(part.quantity_available <= part.quantity) && (
                                    <div className="backorder result">
                                        <p className="title"></p>
                                        <p className="backorder__data result__data"><FontAwesomeIcon icon={solid("circle-exclamation")} color="#A91108" style={{ marginRight: "1ch", fontSize: "1.4375rem" }} />Low or out of stock</p>
                                    </div>
                                )}
                            </>
                        )}





                    </div>
                )
            })
        }

        return order.parts.map((part, index) => {
            return (
                <div key={index} className="result__container">
                    <div className="part-number result">
                        <p className="part-number__data result__data">{part.item_number}</p>
                    </div>
                    <div className="part-description result">
                        <p className="part-description__data result__data">{part.item_description}</p>
                        {part.manufacturer_number && (
                            <p className="part-manufacturer__data result__data">MFG#: {part.manufacturer_number}</p>
                        )}
                    </div>
                    <div className="price result">
                        <p className="price__data result__data">{part.retail_price.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div className="dealer-price result">
                        <p className="dealer-price__data result__data">{part.dealer_price.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div className="quantity result">
                        <p className="quantity__data result__data">{part.quantity}</p>
                    </div>
                    {order.pending_shipment && (
                        <>
                            <div className="quantity_shipped result">
                                <p className="quantity_shipped__data result__data">{part.quantity_shipped}</p>
                            </div>
                            <div className="quantity_backordered result">
                                <p className="quantity_backordered__data result__data">{part.quantity_backordered}</p>
                            </div>
                        </>
                    )}
                    {!order.pending_shipment && (
                        <>
                            {(part.quantity_available <= part.quantity) && (
                                <div className="backorder result">
                                    <p className="backorder__data result__data"><FontAwesomeIcon icon={solid("circle-exclamation")} color="#A91108" style={{ marginRight: "1ch", fontSize: "1.4375rem" }} />Low or out of stock</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )
        })
    }

    console.log(order);

    return (
        <div className="open-order">
            <h1>PO #{order.po_number === "" ? " Unnamed Order" : order.po_number}</h1>
            {type === "confirmed" && (
                <p className="mb-1">Southern Order #{order.southern_order}</p>
            )}
            <p className="bold ">Account #{order.customer_number}</p>
            <p className="bold mb-2">{user.dealer.name_company}</p>
            <p className="">Date Order Placed: {order.order_date}</p>
            {type === "confirmed" && (
                <p className="">Ship Method Noted: {order.shipping_method}</p>
            )}
            {type === "unconfirmed" && (
                <p className="">Ship Method Noted: {order.shipping_method === "default" ? user.dealer.default_shipping.long : order.shipping_method}</p>
            )}
            <p >Freight Minimum: ${order.freight_minimum?.toFixed(2)}</p>
            <p className="mb-3">Status: <span style={{ textTransform: "capitalize" }}>{order.pending_shipment ? "Pending Shipment" : type}</span></p>
            <div className={`order-container ${order.pending_shipment ? "pending-shipment" : ""}`}>
                {width >= 768 && (
                    <div className="open-order-header">
                        <p className="part-number">Part Number</p>
                        <p className="part-description">Part Description</p>
                        <p className="price">Suggested Retail Price</p>
                        <p className="dealer-price">Dealer Price</p>
                        <p className="quantity">Qty Ordered</p>
                        {order.pending_shipment && (
                            <>
                                <p className="quantity_shipped">Qty Shipped</p>
                                <p className="quantity_backordered">Qty Backordered</p>
                            </>
                        )}
                        <p className="backorder"></p>
                    </div>
                )}
                {renderOrder()}
            </div>
            <div>
                <LinkWrapper link="/open-orders" label="Back to Open Orders" containerClass="button button-primary" />
            </div>
        </div>
    )
}

export default OpenOrder;