import { useEffect, useState } from "react";
import api from "../api";
import { useSelector } from "react-redux";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const InvoiceHistory = () => {

    const user = useSelector(state => state.user.data);

    const [orders, setOrders] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                let result = await api.post('/invoice-history', { dealer: user.dealer_number })
                let orders = result.data.orders;
                orders.sort((a, b) => {
                    const dateA = dayjs(a.order_invoice_date, 'MM/DD/YY');
                    const dateB = dayjs(b.order_invoice_date, 'MM/DD/YY');
                    return dateB.unix() - dateA.unix();
                });
                console.log(orders);
                setOrders(orders);
            }
            catch (err) {
                console.warn(err);
                setOrders([])
            }

        }

        getData();
    }, [])

    if (orders === null) {
        return <div>Loading...</div>
    }

    const renderOrders = () => {

        if (user.role !== 'dealer') {
            if (user.dealer_number === null) {
                return <p>No dealer selected. Please select a dealer to continue...</p>
            }
        }

        if (orders.length === 0) {
            return <p>No Invoices Found.</p>
        }

        return orders.map((order) => {
            return (
                <div className="invoice" key={order._id}>
                    <div className="data date">
                        <p className="title">Invoice Date:</p>
                        <p className="content">{order.order_invoice_date}</p>
                    </div>
                    <div className="data invoice-number">
                        {order.invoice_total < 0 && (
                            <p className="title">Credit Memo:</p>
                        )}
                        {order.invoice_total >= 0 && (
                            <p className="title">Invoice Number:</p>
                        )}
                        <p className="content"><Link to={`/invoice-history/${order._id}`}>{order.invoice_number}</Link></p>
                    </div>
                    <div className="data order-number">
                        <p className="title">Purchase Order Number:</p>
                        <p className="content">{order.po_number} </p>
                    </div>
                    <div className="data total">
                        <p className="title">Invoice Total:</p>
                        <p className="content">{order.invoice_total.toLocaleString('en-US', { style:"currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })} </p>
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            <h1>Invoice History</h1>
            <p>The last 3 months of invoices.</p>
            <div className="invoice-container">
                {renderOrders()}
            </div>
        </div>
    )
}

export default InvoiceHistory;