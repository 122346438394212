import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import api from "../api";

import toast from 'react-hot-toast';
import NotificationToast from "../components/NotificationToast";
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


import { useDispatch } from "react-redux";
import { setReduxUser } from '../redux/userSlice';
import AppContext from '../context/AppContext';


const ChangeEmail = () => {


    const changePasswordValidation = Yup.object().shape({
        password: Yup.string().required("Required"),
        email: Yup.string().email("Invalid Email").required('Password is required'),
        email_confirm: Yup.string().oneOf([Yup.ref('email'), null], 'Email must Match').required('Confirm Email is required.')
    })

    const [ status, setStatus ] = useState("start");
    const dispatch = useDispatch();

    const context = useContext(AppContext);
    const { setToken, setUser } = context;

    if (status === "done") {
        return(
            <div>
                <h1>Email Changed</h1>
                <p>Thank you for changing your email address.</p>
                <Link className="button button-primary" to="/">Return to Dealer home page</Link>
            </div>
        )
    }

    return (
        <div>
            <h1>Change Email Address</h1>
            <Formik
                initialValues={{ password: "", email: "", email_confirm: "" }}
                validationSchema={changePasswordValidation}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        let result = await api.post("userChangeEmail", { email: values.email, password: values.password })
                        console.log(result);
                        setSubmitting(false); 
                        

                        // setToken(result.data.token);
                        // setUser(result.data.user);
                        dispatch(setReduxUser(result.data.user));

                        setStatus("done");

                    } catch (err) {
                        console.warn(err);
                        let message = err.response.data.message;
                        if (err.response.status === 403 ) {
                            message = "Invalid Password."
                        }
                        toast.custom(
                            (t) => (
                                <NotificationToast header={'Error'} body={message} type="danger" toastObject={t} />
                            ), { duration: 4000, id: "reset-failed" }
                        );

                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="field-container">
                            <label htmlFor="password">Current Password</label>
                            <ErrorMessage name="password" component="div" className='error' />
                            <Field type="password" name="password" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="email">New Email Address</label>
                            <ErrorMessage name="email" component="div" className='error' />
                            <Field type="text" name="email" />
                        </div>
                        <div className="field-container">
                            <label htmlFor="email_confirm">Retype New Email Address</label>
                            <ErrorMessage name="email_confirm" component="div" className='error' />
                            <Field type="text" name="email_confirm" />
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ChangeEmail;