import { useSelector } from "react-redux"
import LinkWrapper from "../components/LinkWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import NotificationToast from "../components/NotificationToast";

const DealerConfirm = () => {

    const user = useSelector(state => state.user.data);
    const cart = useSelector(state => state.cart.products);

    const navigate = useNavigate();

    const { data } = useParams();


    if (cart.length === 0) {
        console.log("Empty Cart!");
        toast.custom(
            (t) => (
                <NotificationToast header={'Dealer Changed'} body={"Checkout aborted due to change in selected dealer."} type="danger" toastObject={t} />
            ), { duration: 4000, id: "navigation-failed"}
        );
        navigate("/stock");
    }


    return(
        <div className="dealer-confirm">
            <h1>Dealer Confirmation</h1>
            <p>Please confirm that this order is for #{user.dealer.customer_number} ({user.dealer.name_company}). If this is not the correct dealer, please change the dealer that you are working as.</p>
            <LinkWrapper link={`/finalize/${data}`} label="Confirm" position="right" containerClass="button button-primary">
                <FontAwesomeIcon icon={solid("chevron-right")} />
            </LinkWrapper>
        </div>
    )
}

export default DealerConfirm;